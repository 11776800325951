import React, { useContext } from 'react';
import FormControl from './FormControl';
import SubmitButton from './SubmitButton';
import useForm, { validateIsRequired } from '../hooks/useForm';
import { TrackingContext, SignupContext } from '../helpers/context';
import SocialShare from './SocialShare';
import EmailPrivacyMessage from './EmailPrivacyMessage';
import CountryOptions from './isoCountryOptions';
import StateOptions from './isoStateOptions';
import validations from '../helpers/validations';

const noOfEmployeesOptions = [
  '1-10',
  '11-51',
  '51-200',
  '201-500',
  '501-1000',
  '1001+',
];

const EmployeeCountOptions = () => {
  return (
    <>
      <option value='' selected disabled hidden>
        Choose here
      </option>
      {noOfEmployeesOptions.map(val => (
        <option key={val} value={val}>
          {val}
        </option>
      ))}
    </>
  );
};

const CompanyForm = () => {
  const signup = useContext(SignupContext);
  const { ga4Track } = useContext(TrackingContext);

  const { submitForm, handleChange, submitting, errors, fields } = useForm({
    action: 'bootstrapCompanySignup',
    validations: {
      email: validations.email('email', { required: true }),
      fullName: validations.presence('Full name'),
      companyName: validations.presence('Company name'),
      companyWebsite: validations.url('Company Website', { required: true }),
      shortDescription: validations.presence('Short description'),
      foundingYear: validations.year('Founding Year', { required: true }),
      noOfEmployees: validations.presence('No. of employees'),
      country: validations.presence('Country'),
      linkToJobsPage: validations.url('Link to jobs page', { required: true }),
    },
    successFn: () => {
      signup.showConfirmation();
      ga4Track('Bootstrap - Company form submit', {});
    },
  });

  const isUsaSelected = fields?.country === 'United States of America';

  return (
    <>
      <h5 className='heading-5'>
        "I commit to building a bootstrapped company — focused on purpose,
        self-reliance, and long-term value."
      </h5>

      <form
        acceptCharset='UTF-8'
        onSubmit={submitForm}
        className='w-full w-max-md'
        id='company-form'
      >
        <fieldset disabled={submitting} className='pt-4'>
          <FormControl
            error={errors.fullName}
            id='fullName'
            label='Full name'
            onChange={handleChange}
          />

          <FormControl
            error={errors.email}
            id='email'
            label='Email'
            onChange={handleChange}
          />

          <div className='flex flex-wrap'>
            <FormControl
              error={errors.companyName}
              id='companyName'
              label='Company name'
              onChange={handleChange}
              className='w-full md:w-1/2 md:pr-6'
            />

            <FormControl
              error={errors.companyWebsite}
              id='companyWebsite'
              label='Company website'
              onChange={handleChange}
              className='w-full md:w-1/2 md:pl-6'
            />
          </div>

          <div className='flex flex-wrap'>
            <FormControl
              error={errors.foundingYear}
              id='foundingYear'
              label='Founding year'
              onChange={handleChange}
              className='w-full md:w-1/2 md:pr-6'
            />

            <FormControl
              error={errors.noOfEmployees}
              id='noOfEmployees'
              label='No. of employees'
              onChange={handleChange}
              input={
                <select>
                  <EmployeeCountOptions />
                </select>
              }
              className='w-full md:w-1/2 md:pl-6'
            />
          </div>

          <div className='flex flex-wrap'>
            <FormControl
              error={errors.country}
              id='country'
              label='Country'
              onChange={handleChange}
              className='w-full md:w-1/2 md:pr-6'
              input={
                <select>
                  <CountryOptions />
                </select>
              }
            />

            {isUsaSelected && (
              <FormControl
                error={errors.state}
                id='state'
                label='State (U.S. only)'
                onChange={handleChange}
                className='w-full md:w-1/2 md:pl-6'
                input={
                  <select>
                    <StateOptions />
                  </select>
                }
              />
            )}
          </div>

          <FormControl
            error={errors.linkToJobsPage}
            id='linkToJobsPage'
            label='Link to jobs page'
            onChange={handleChange}
          />

          <FormControl
            type='textarea'
            error={errors.shortDescription}
            id='shortDescription'
            label='Short company description'
            onChange={handleChange}
          />

          <div>
            <SubmitButton
              className='btn btn-teal block mt-5 ml-auto px-8 py-1'
              submitting={submitting}
            >
              Sign the pledge
            </SubmitButton>
            <SocialShare />
            <EmailPrivacyMessage version='company' />
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default CompanyForm;

import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import './Header.css';
import Logo from './Logo';

export default ({ hasDarkBackground }) => {
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showChooseStep } = useContext(SignupContext);

  return (
    <header className='BootstrapHeader'>
      <a
        className='BootstrapHeader__logo-wrapper no-animated-line hover:no-underline'
        href='/'
      >
        <Logo
          className='BootstrapHeader__logo-svg'
          width={46}
          hasDarkBackground={hasDarkBackground}
        />
      </a>

      <button
        type='button'
        className='BootstrapHeader__join-button btn bg-teal-600 btn-rounded text-white mt-8 mr-8'
        onClick={() => {
          showChooseStep();
          openBootstrapSignup({});
        }}
      >
        Join the movement
      </button>
    </header>
  );
};

import React, { useLayoutEffect, useContext } from 'react';
import { SignupContext } from '../helpers/context';
import ListSignup from './ListSignup';
import IndividualForm from './IndividualForm';
import CompanyForm from './CompanyForm';
import SignupConfirmation from './SignupConfirmation';

const Signup = () => {
  const { step } = useContext(SignupContext);

  return (
    <>
      {step === 'choose' && <ListSignup />}
      {step === 'company' && <CompanyForm />}
      {step === 'individual' && <IndividualForm />}
      {step === 'confirmation' && <SignupConfirmation />}
    </>
  );
};

export default Signup;

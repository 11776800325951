import React from 'react';
import './Hero.css';

export default ({ header, subheader }) => {
  return (
    <div className='BootstrapHero'>
      <div className='BootstrapHeaderBumper' />
      <div className='BootstrapHero__content'>
        <div className='BootstrapHero__movement'>Bootstrap Movement</div>
        <h1 className='BootstrapHero__heading text-bootstrap-gradient'>
          {header}
        </h1>
        <p className='paragraph-one md:subheading-one'>{subheader}</p>
      </div>
    </div>
  );
};

const bigInt = require('big-integer');

export const getRandom = () => {
  return (window.crypto || window.msCrypto).getRandomValues(
    new Uint32Array(1)
  )[0];
};

export const generateTrackingId = () => {
  const secondsSinceEpoch = Math.round(new Date().getTime() / 1000);

  const pct = getRandom() / 4294967295;

  return bigInt(secondsSinceEpoch)
    .shiftLeft(32)
    .or(bigInt(2 << 24))
    .or(bigInt(Math.round(pct * 2 ** 24)))
    .toString();
};

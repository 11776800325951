import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import './Hero.css';
import './PrinciplesHero.css';
import PrinciplesLaceRight from '../images/principles-lace-right.svg';
import PrinciplesLaceLeft from '../images/principles-lace-left.svg';

const Laces = () => (
  <div className='hidden lg:block'>
    <img className='BootstrapPrinciplesLeftLace' src={PrinciplesLaceLeft} />
    <img className='BootstrapPrinciplesRightLace' src={PrinciplesLaceRight} />
  </div>
);

const PrinciplesHero = () => {
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showIndividualForm, showCompanyForm } = useContext(SignupContext);

  return (
    <>
      <div className='BootstrapHero pb-16'>
        <div className='BootstrapHeaderBumper' />
        <div className='BootstrapHero__content'>
          <div className='BootstrapHero__movement'>Bootstrap Movement</div>
          <h1 className='BootstrapHero__heading text-bootstrap-gradient'>
            Operating principles
          </h1>
          <p className='paragraph-one md:subheading-one'>
            Bootstrapping is an operating philosophy. It goes beyond how a
            business is funded — the beliefs and benefits extend well past the
            startup phase. You can apply the principles to any new endeavor or
            to enhance the way you work.
          </p>

          <div className='BootstrapHero__buttons'>
            <button
              onClick={() => {
                showIndividualForm();
                openBootstrapSignup();
              }}
              className='BootstrapHero__button btn btn-teal btn-rounded'
            >
              Sign your name
            </button>
            <button
              onClick={() => {
                showCompanyForm();
                openBootstrapSignup();
              }}
              className='BootstrapHero__button btn btn-teal btn-rounded'
            >
              List your company
            </button>
          </div>
        </div>
      </div>
      <Laces />
    </>
  );
};

export default PrinciplesHero;

import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { window } from 'browser-monads';
import Cookies from 'js-cookie';
import renderIf from 'render-if';
import { TrackingContext } from '../helpers/context';
import TrackPixel from '../components/TrackPixel';
import { generateTrackingId } from '../helpers/trackingId';
import withSiteMetadata from '../helpers/withSiteMetadata';
import cx from 'classnames';
import logAnalyticsInfoNonProd from '../helpers/logAnalyticsInfoNonProd';
import { formatEventName } from '../helpers/googleAnalytics';

// Use a reducer to atomically set both consent and the tracking ID.
const reducer = (state, action) => {
  switch (action.type) {
    case 'CONSENT':
      return { ...state, trackingConsent: true, trackingId: action.trackingId };
    case 'SET_LOCATION':
      return { ...state, userInGDPR: action.userInGDPR };
    default:
      return state;
  }
};

export const TrackingProvider = ({ siteMetadata, children }) => {
  const [displayBanner, setDisplayBanner] = useState(false);
  const [{ trackingConsent, trackingId, userInGDPR }, dispatch] = useReducer(
    reducer,
    {
      trackingConsent: Boolean(
        Cookies.get('_aha_consent') ||
          Cookies.get('_aha_not_gdpr') ||
          Cookies.get('_aha_not_eu')
      ),
      trackingId: Cookies.get('_aha_t'),
      userInGDPR:
        Cookies.get('_aha_not_gdpr') !== 'true' &&
        Cookies.get('_aha_not_eu') !== 'true',
    }
  );

  const ga4Track = useCallback(
    (eventName, eventProperties) => {
      const command = 'event'; // This is a param gtag accepts different values for but we only use event
      const formattedEventName = formatEventName(eventName);
      logAnalyticsInfoNonProd(
        'ga4Track:',
        `if(trackingConsent: ${trackingConsent} && window.gtag: ${!!window.gtag})`,
        `window.gtag(${command}, ${formattedEventName}, ${JSON.stringify(
          eventProperties
        )})`
      );
      if (trackingConsent) {
        if (window.gtag)
          window.gtag(command, formattedEventName, eventProperties);

        fetch(`/.netlify/functions/trackEvent`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trackingId,
            trackerMethod: 'analytics_track',
            eventName: eventName,
            properties: {
              ...eventProperties,
            },
          }),
        });
      }
    },
    [trackingConsent, trackingId]
  );

  const host = window.location.hostname
    .split('.')
    .slice(-2)
    .join('.');

  const acceptTracking = useCallback(
    (explicit = true) => {
      if (!trackingId) {
        const newTrackingId = generateTrackingId();

        // If the user has explicitly granted consent, log it
        // and set an extra cookie. Otherwise they're just not
        // in the EU.
        if (explicit) {
          fetch(`/.netlify/functions/grantConsent`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ trackingId }),
          });

          Cookies.set('_aha_consent', newTrackingId, {
            path: '/',
            domain: host,
            secure: true,
            expires: 365 * 2, // days
          });
        }
        Cookies.set('_aha_t', newTrackingId, {
          path: '/',
          domain: host,
          secure: true,
          expires: 365 * 2, // days
        });

        dispatch({
          type: 'CONSENT',
          trackingId: newTrackingId,
        });
      }
    },
    [dispatch, trackingId, host]
  );

  useEffect(() => {
    if (!trackingConsent && !displayBanner) {
      fetch('https://secure.aha.io/location.json')
        .then(response => response.json())
        .then(location => {
          if (
            location.eu ||
            /[?&]eu=true/.test(window.location.search) ||
            location.brazil ||
            /[?&]brazil=true/.test(window.location.search)
          ) {
            setDisplayBanner(true);
            dispatch({
              type: 'SET_LOCATION',
              userInGDPR: true,
            });
          } else {
            Cookies.set('_aha_not_gdpr', 'true', {
              path: '/',
              domain: `.${host}`,
            });
            acceptTracking(false);
            dispatch({
              type: 'SET_LOCATION',
              userInGDPR: false,
            });
          }
        });
    }
  }, [trackingConsent, displayBanner, host, acceptTracking]);

  return (
    <TrackingContext.Provider
      value={{
        trackingConsent,
        trackingId,
        displayBanner: displayBanner && !trackingConsent,
        ga4Track,
        userInGDPR,
      }}
    >
      {renderIf(displayBanner && !trackingConsent)(
        <div className='py-2 px-3 sm:px-10 sm:py-5 flex flex-col sm:flex-row justify-center items-center text-white text-sm bg-gray-700 h-32 md:h-24 lg:h-20 w-full fixed z-1000 bottom-0'>
          <p>
            Aha! uses cookies to provide a better browsing experience, analyze
            site traffic, and serve advertisements. Read our{' '}
            <a
              href='https://aha.io/legal/privacy_policy'
              className='text-white'
            >
              Privacy policy
            </a>{' '}
            to learn how we use cookies and how to manage them.
          </p>
          <button
            className='mt-2 sm:mt-0 btn btn-sm btn-sm-wide btn-white px-3 ml-5'
            onClick={() => acceptTracking(true)}
          >
            Accept
          </button>
        </div>
      )}
      <div className={cx({ 'TrackingProvider--shown': displayBanner })}>
        {children}
      </div>

      <TrackPixel />
    </TrackingContext.Provider>
  );
};

export default withSiteMetadata(TrackingProvider);

import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import './ListSignup.css';

export default () => {
  // PurgeCSS: md:heading-1
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showIndividualForm, showCompanyForm } = useContext(SignupContext);

  return (
    <div className='BootstrapListSignup mt-8 py-8'>
      <div className='BootstrapListSignup__grid'>
        <div className='BootstrapListSignup__card mx-4'>
          <span>
            <i className='fa-regular fa-signature fa-3x text-bootstrap-gradient' />
          </span>
          <h5 className='heading-5'>Sign the bootstrapper’s pledge</h5>
          <p>
            We all benefit when companies and teams operate efficiently with
            less waste. Bootstrappers are self-reliant, purpose-driven, and take
            a long-range view. No matter what you work on or where, we hope you
            will join us.
          </p>
          <button
            onClick={() => {
              showIndividualForm();
              openBootstrapSignup();
            }}
            className='BootstrapListSignup__button btn bg-teal-600 btn-rounded text-md text-white'
          >
            Add your name <span className='fa-regular fa-arrow-right' />
          </button>
        </div>

        <div className='BootstrapListSignup__card mx-4'>
          <span>
            <i className='fa-regular fa-apartment text-bootstrap-gradient fa-3x' />
          </span>
          <h5 className='heading-5'>Bootstrapped companies</h5>
          <p>
            Bootstrapped organizations are proudly self-funded and do not have
            plans to raise outside investment for at least 24 months. Add your
            company so people can find you or{' '}
            <a className='text-green-700' href='/companies'>
              browse
            </a>{' '}
            the directory to discover others who are building sustainable
            businesses.
          </p>
          <button
            onClick={() => {
              showCompanyForm();
              openBootstrapSignup();
            }}
            className='BootstrapListSignup__button btn bg-teal-600 btn-rounded text-md text-white'
          >
            List your company <span className='fa-regular fa-arrow-right' />
          </button>
        </div>
      </div>
    </div>
  );
};

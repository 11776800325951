import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import './PrinciplesBenefits.css';

const list = [
  {
    heading: 'Greater depth',
    text: 'You are an expert. It might be painful at times, but you will know your business more deeply than anyone else.',
  },
  {
    heading: 'Fewer distractions',
    text: 'You have one aim. The focus is what you are building and selling — not on outside interests.',
  },
  {
    heading: 'Creative space',
    text: 'You can try anything. There is no need to convince investors to rally around a new idea. Unconventional thinking is rewarded.',
  },
  {
    heading: 'Learned resilience',
    text: 'You are strong. Most successful founders will push past a series of challenges in a productive way. Each hurdle lays the groundwork for future adaptability.',
  },
  {
    heading: 'Earned pride',
    text: 'You feel good. If you succeed then you are most likely living your values, contributing in a positive way, and creating opportunities for others. ',
  },
  {
    heading: 'Ongoing adventure',
    text: 'You keep seeking. There is freedom to steer your own destiny. Inspire those around you to bootstrap and build their way.',
  },
];

const PrinciplesBenefits = () => {
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showIndividualForm, showCompanyForm } = useContext(SignupContext);

  return (
    <>
      <div className='BootstrapPrinciplesBenefits--slant' />
      <div className='BootstrapPrinciplesBenefits mb-6 pb-48'>
        <h3 className='BootstrapPrinciplesBenefits__heading heading-3 text-center'>
          The benefits of bootstrapping
        </h3>
        <p className='BootstrapPrinciplesBenefits__subheading text-center'>
          Bootstrappers act with integrity, do things differently, and gain
          unique skills. You get what you give, including the following
          benefits:
        </p>

        <div className='BootstrapPrinciplesBenefits__list'>
          {list.map(({ heading, text }) => (
            <div
              key={heading}
              className='BootstrapPrinciplesBenefits__list-item mt-4'
            >
              <div className='BootstrapPrinciplesBenefits__list-item-heading'>
                {heading}
              </div>
              <p className='meta'>{text}</p>
            </div>
          ))}
        </div>

        <div className='BootstrapPrinciplesBenefits__prompt mt-8'>
          <span
            onClick={() => {
              showIndividualForm();
              openBootstrapSignup();
            }}
            className='BootstrapPrinciplesBenefits__prompt-link'
          >
            Sign your name <i className='fa fa-thin fa-arrow-right' />{' '}
          </span>
          <span
            onClick={() => {
              showCompanyForm();
              openBootstrapSignup();
            }}
            className='BootstrapPrinciplesBenefits__prompt-link'
          >
            List your company <i className='fa fa-thin fa-arrow-right' />{' '}
          </span>
        </div>
      </div>
    </>
  );
};

export default PrinciplesBenefits;

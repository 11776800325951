import React, { useContext } from 'react';
import { ModalContext } from '../helpers/context';

export default function ModalCloseButton(props) {
  const { closeModal } = useContext(ModalContext);

  return (
    <button
      className={
        props.className ||
        'px-3 py-2 sm:px-1 sm:py-0 absolute right-0 sm:right-2 top-0 sm:top-1 hover:text-blue-500 focus:outline-none focus:text-blue-500'
      }
      type='button'
      onClick={closeModal}
    >
      {props.children || <i className='fa fa-times' />}
    </button>
  );
}

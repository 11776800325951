import React from 'react';
import Signup from './Signup';
import ModalCloseButton from './ModalCloseButton';

const SignupModal = () => {
  return (
    <>
      <div className='flex space-between'>
        <h3 className='heading-3 text-bootstrap-gradient mb-8'>
          Join the movement
        </h3>
        <ModalCloseButton />
      </div>
      <Signup />
    </>
  );
};

export default SignupModal;

import React from 'react';
import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const allCountries = Object.values(
  countries.getNames('en', { select: 'official' })
).sort();

const selectableFirst = ['Remote/Global', 'United States of America', 'Canada'];

const reorderedCountries = [
  ...selectableFirst,
  ...allCountries.filter(country => !selectableFirst.includes(country)),
];

const isoCountryOptions = () => {
  return (
    <>
      <option value='' selected disabled hidden>
        Choose here
      </option>
      {reorderedCountries.map(val => (
        <option key={val} value={val}>
          {val}
        </option>
      ))}
    </>
  );
};

export default isoCountryOptions;

import React from 'react';
import Link from './Link';

const IndividualPrivacyMessage = () => {
  return (
    <p className='meta my-4'>
      By signing, you agree that Aha! may publish your pledge with your first
      name, initial of last name, and state or country. You also agree to
      receive quarterly email messages from Aha! — you can unsubscribe from
      emails or remove your pledge at any time. See our
      <a
        href='https://aha.io/legal/privacy_policy'
        target='_blank'
        rel='noopener noreferrer'
        className='text-green-700'
      >
        &nbsp;Privacy Policy&nbsp;
        <i class='fa-solid fa-arrow-up-right-from-square'></i>&nbsp;
      </a>
      to learn more.
    </p>
  );
};

const CompanyPrivacyMessage = () => {
  return (
    <p className='meta my-4'>
      By signing, you agree that Aha! may publish your company to our
      <a
        href='/companies'
        target='_blank'
        rel='noopener noreferrer'
        className='text-green-700'
      >
        &nbsp;bootstrap directory{' '}
        <i className='fa-solid fa-arrow-up-right-from-square' />
        .&nbsp;
      </a>
      You also agree to receive quarterly email messages from Aha! — you can
      unsubscribe from emails or remove your company at any time. See our
      <a
        href='https://aha.io/legal/privacy_policy'
        target='_blank'
        rel='noopener noreferrer'
        className='text-green-700'
      >
        &nbsp;Privacy Policy&nbsp;
        <i class='fa-solid fa-arrow-up-right-from-square'></i>&nbsp;
      </a>
      to learn more.
    </p>
  );
};

export default function EmailPrivacyMessage({ version }) {
  if (version === 'company') return <CompanyPrivacyMessage />;

  return <IndividualPrivacyMessage />;
}

import { useCallback, useContext, useReducer, useState } from 'react';
import { camelizeKeys } from 'humps';
import { FlashMessageContext } from '../helpers/context';
import { isNil, isEmpty } from 'lodash';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        errors: {
          ...state.errors,
          [action.name]: false,
        },
        fields: {
          ...state.fields,
          [action.name]: action.value,
        },
      };

    case 'SET_ERRORS':
      return {
        errors: action.errors,
        fields: state.fields,
      };

    default:
      return state;
  }
};

export const validateIsRequired = subject => {
  return value => {
    if (isNil(value) || isEmpty(value) || value === '') {
      return `${subject} is required`;
    }
  };
};

export const validateYear = value => {
  if (value.length !== 4 || value[0] !== 1) {
    return `Please enter a valid year`;
  }
};

export default function useForm({
  initState = {},
  url,
  action,
  successFn,
  formData,
  validations = {},
  options = {},
}) {
  const { setFlashMessage } = useContext(FlashMessageContext);
  const [state, dispatch] = useReducer(reducer, {
    errors: {},
    fields: initState,
  });
  const [submitting, setSubmitting] = useState(false);

  const setField = useCallback((name, value) => {
    dispatch({
      type: 'SET_FIELD',
      name,
      value,
    });
  }, []);

  const setErrors = useCallback(errors => {
    dispatch({
      type: 'SET_ERRORS',
      errors,
    });
  }, []);

  const { fields, errors } = state;

  async function submitForm(e) {
    if (e) e.preventDefault();

    const stepForm =
      e &&
      e.currentTarget &&
      e.currentTarget.querySelectorAll('#secondFormButton').length > 0;

    setSubmitting(true); // set before validations are run because domain check hits server, needs spinner

    // Run validations and collect any errors.
    const validationErrors = {};
    const validationEntries = Object.entries(validations);
    for (let i = 0; i < validationEntries.length; i++) {
      const [field, validation] = validationEntries[i];

      const validateResult = await validation(fields[field]);
      if (validateResult) {
        validationErrors[field] = validateResult;
      }
    }
    if (
      (!stepForm || (stepForm && validationErrors.email)) &&
      Object.keys(validationErrors).length > 0
    ) {
      setErrors(validationErrors);
      setSubmitting(false);
      return;
    }

    try {
      const formUrl = url || `/.netlify/functions/${action}`;
      const response = await fetch(formUrl, {
        method: 'POST',
        headers: options.headers || {
          'Content-Type': 'application/json',
        },
        body: formData ? formData(fields) : JSON.stringify(fields),
        ...options,
      }).then(res => res.json());
      const { result, errors, ...rest } = camelizeKeys(response);
      setSubmitting(false);
      if (result === 'error') {
        if (errors.unknown) {
          setFlashMessage('error', errors.unknown);
        } else {
          setErrors(errors);
        }
      } else {
        successFn && successFn({ fields, result, errors, ...rest });
      }
    } catch (e) {
      setSubmitting(false);
      setFlashMessage(
        'error',
        'An unknown error occurred; please try again or contact support@aha.io directly for assistance.'
      );
    }
  }

  async function validateField(f) {
    // Run client-side validations and collect any errors.
    const validationErrors = {};
    const validationEntries = Object.entries(validations);
    for (let i = 0; i < validationEntries.length; i++) {
      const [field, validation] = validationEntries[i];

      if (field === f) {
        const validateResult = await validation(fields[field]);
        if (validateResult) {
          validationErrors[field] = validateResult;
        }
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    } else {
      return true;
    }
  }

  /**
   * Handle change has two options to use. This can be directly tied to an input, say the Form Control, by directly using the event, like:
   *
   * ```
   * <FormControl onChange={handleChange} />
   * ```
   * In this option, we are using the event triggered by the input attribute. You can also pass custom changes, by passing two parameters, the 'nameOfField, valueOfField`
   *
   * For an example of this, see ApplyToPartnerForm.js
   */
  const handleChange = useCallback(
    (e, fieldValue) => {
      // If element is connected to an input using the event onChange
      let value = e?.target?.value;
      if (value) {
        // If the target element is a checkbox and it has no DOM attribute that specifies a value, assume it is a boolean checkbox
        // where a checked state is true and an unchecked state is false.
        if (
          e.target.type === 'checkbox' &&
          e.target.getAttribute('value') == undefined // eslint-disable-line eqeqeq
        ) {
          value = !!e.target.checked;
        }
        setField(e.target.name, value);
      } else if (typeof e === 'string') {
        // This is a custom onChange handler. The first parameter must be the name of the field, and the second is the new value
        setField(e, fieldValue);
      }
    },
    [setField]
  );

  return {
    handleChange,
    submitForm,
    fields,
    setField,
    errors,
    setErrors,
    submitting,
    validateField,
  };
}

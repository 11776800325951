import '../components/Layout.css'; // Must come before any other components, otherwise we risk that component's styles being overwritten by the styles in Layout.css
import * as React from 'react';
import Header from './Header';
import Footer from './Footer';
import SignupProvider from '../providers/SignupProvider';
import ModalProvider from '../providers/ModalProvider';
import FlashProvider from '../providers/FlashProvider';
import TrackingProvider from '../providers/TrackingProvider';

import '@aha-app/aha-base/dist/assets/font-awesome/all.css';

// TailwindUtilities.css should always come after ALL OTHER CSS imports.
// Otherwise tailwind utilities classes will not be able to modify any css classes defined in components.
import '../components/TailwindUtilities.css';

const Layout = ({ hasDarkHeroBackground = false, children }) => {
  return (
    <main className='Page'>
      <TrackingProvider>
        <FlashProvider>
          <SignupProvider>
            <ModalProvider>
              <Header hasDarkBackground={hasDarkHeroBackground} />
                {children}
              <Footer />
            </ModalProvider>
          </SignupProvider>
        </FlashProvider>
      </TrackingProvider>
    </main>
  );
};

export default Layout;

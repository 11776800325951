import React, { useContext } from 'react';
import { SignupContext } from '../helpers/context';
import { Link } from 'gatsby';
import SocialShare from './SocialShare';
import EmailPrivacyMessage from './EmailPrivacyMessage';

const IndividualConfirmation = () => {
  return (
    <>
      <div>
        <p className='mt-4 mb-8'>
          Congrats on choosing to set your own terms, fellow bootstrapper.
        </p>
        <p>
          Check your inbox for a confirmation email and a link to share your
          pledge. Watch for your name on the{' '}
          <Link to='/#recently-signed' className='text-green-700'>
            bootstrapper's list
          </Link>
          .
        </p>
      </div>
      <SocialShare />
      <EmailPrivacyMessage version='individual' />
    </>
  );
};

const CompanyConfirmation = () => {
  return (
    <>
      <div>
        <p className='mt-4 mb-8'>
          Congrats on choosing to set your own terms, fellow bootstrapper.
        </p>
        <p>
          We will review your submission, add you to the{' '}
          <Link to='/companies' className='text-green-700'>
            company directory
          </Link>
          , or reach out with any questions.
        </p>
      </div>
      <SocialShare />
      <EmailPrivacyMessage version='company' />
    </>
  );
};

const SignupConfirmation = () => {
  const { submittedStep } = useContext(SignupContext);

  if (submittedStep === 'company') return <CompanyConfirmation />;
  if (submittedStep === 'individual') return <IndividualConfirmation />;

  return <>We don't know how you got here, but good work!</>;
};

export default SignupConfirmation;

// If you have copilot, just
// format the following object to have keys same as above values with the following data:
// <past in table data here>
// it will do it

// When copy+pasting out of notes from Aha!, quick way to get this done is:
// 1) macro to add 's around each line (ex: put on key u, @u)
// 2) turn into array
// 3) split on \t (this is delimiter out of Aha! tables)
// 4) format company objects
// 5) copy output and put in here
// Below is an example you can pop into a google chrome snippet to see

/*
const buildCompany = data => {
  return {
    name: data[0],
    description: data[1],
    founded: data[2],
    employeeCount: data[3],
    location: data[4],
    homepageUrl: data[5],
    jobsUrl: data[6],
  };
};

const buildTranche = listOfStrs => {
  return listOfStrs.map(str => {
    return buildCompany(str.split('\t'));
  });
};

const tranche2raw = [
  'Mobicip	Internet filter and parental control app	2008	51-200	California, USA	https://www.mobicip.com	https://www.mobicip.com/about',
  'IKONA Partners	Independent technology investment bank	2022	1-10	California, USA	https://www.ikonapartners.com/	https://www.ikonapartners.com/',
  '2ndVault	Digital vault for important information	2020	1-10	USA	https://2ndvault.com/	https://2ndvault.com/about-us/',
  'IMERSHEN, LLC	Interactive corporate training	2020	1-10	Arizona, USA	https://imershen.co	https://imershen.co',
  'Frameology	Print-on-demand company	2015	1-10	USA	https://frameology.com/	https://angel.co/company/frameology/jobs ',
  'Z-Terra	Seismic imaging software and services	2010	51-200	USA	https://www.z-terra.com/	https://www.z-terra.com/9I7gsY2kIW8m/careers/',
  'IdeaScale	Innovation management solution	2010	11-51	USA	http://ideascale.com	https://ideascale.com/ideascale-careers/',
  'They Got Acquired	Media company reporting on small-scale acquisitions	2021	1-10	USA	https://theygotacquired.com/ 	https://theygotacquired.com/careers',
  'Product10x	Startup accelerator	2019	1-10	California, USA	https://product10x.com/	https://product10x.com/',
  'QuantFish	Statistics education and research	2021	1-10	West Virginia, USA	https://www.goquantfish.com/	https://www.goquantfish.com/teach',
  'Userflow, Inc.	User onboarding platform	2018	1-10	California, USA	https://userflow.com	https://userflow.com',
  'Rolling Insights	Sports data insights provider	2011	1-10	Canada	https://rolling-insights.com/	https://rollingwave.hrpartner.io/jobs',
  'Onederful Finance	Patient financing options website	2020	1-10	New York, USA	https://onederfulfinance.com	https://onederfulfinance.com/jobs/csm',
  'Halfbrick	Mobile video game developer	2001	51-200	Australia	https://www.halfbrick.com	https://www.halfbrick.com/careers',
  'WebHR	All-in-one social HR software	2011	51-200	USA	https://web.hr	https://web.hr/careers',
  'incluude	Workplace and community diversity platform	2021	1-10	Australia	https://incluude.com	https://incluude.com/jobs',
  'The Marygold Companies	Global holding company	2015	51-200	Nevada, USA	http://www.themarygoldcompanies.com 	http://www.themarygoldcompanies.com',
];

copy(buildTranche(tranche2raw));
*/

const tranche1 = [
  {
    name: 'Aha!',
    description: "The world's #1 product development software",
    founded: '2013',
    employeeCount: '51-200',
    location: 'Remote',
    homepageUrl: 'https://aha.io',
    jobsUrl: 'https://www.aha.io/company/careers/current-openings',
  },
  {
    name: 'Ahrefs',
    description: 'Search engine optimization and marketing analytics',
    founded: '2010',
    employeeCount: '51-200',
    location: 'Remote and Singapore',
    homepageUrl: 'https://ahrefs.com/',
    jobsUrl: 'https://ahrefs.com/jobs',
  },
  {
    name: 'Baremetrics ',
    description: 'Analytics and insights platform',
    founded: '2013',
    employeeCount: '11-50',
    location: 'Remote',
    homepageUrl: 'https://baremetrics.com/',
    jobsUrl: 'https://baremetrics.com/about#careers',
  },
  {
    name: 'CG Cookie',
    description: 'Learning platform for creators of digital 3D graphics',
    founded: '2007',
    employeeCount: '11-50',
    location: 'Remote',
    homepageUrl: 'https://cgcookie.com/',
    jobsUrl: 'https://www.linkedin.com/company/cg-cookie/jobs/',
  },
  {
    name: 'ConvertKit',
    description: 'SaaS email marketing platform',
    founded: '2013',
    employeeCount: '51-200',
    location: 'Remote',
    homepageUrl: 'https://convertkit.com/',
    jobsUrl: 'https://convertkit.com/careers',
  },
  {
    name: 'Doist',
    description: 'Productivity and communication apps',
    founded: '2007',
    employeeCount: '51-200',
    location: 'Remote',
    homepageUrl: 'https://doist.com/',
    jobsUrl: 'https://doist.com/careers#open-roles',
  },
  {
    name: 'Facet',
    description: 'Technical recruiting for software and hardware developers',
    founded: '2018',
    employeeCount: '11-50',
    location: 'Lehi, UT',
    homepageUrl: 'https://www.facet.net/',
    jobsUrl: 'https://www.linkedin.com/company/facetdev/jobs/',
  },
  {
    name: 'Financer.com',
    description: 'Loan and finance comparison engine for loans and mortgages',
    founded: '2015',
    employeeCount: '11-50',
    location: 'Remote',
    homepageUrl: 'https://financer.com/',
    jobsUrl: 'https://financer.com/careers/',
  },
  {
    name: 'Gravity Payments',
    description: 'Credit card processing and financial services',
    founded: '2004',
    employeeCount: '201-500',
    location: 'Remote',
    homepageUrl: 'https://gravitypayments.com/',
    jobsUrl: 'https://gravitypayments.com/careers/#open-positions',
  },
  {
    name: 'GrowSurf',
    description: 'Referral software for tech startups',
    founded: '2017',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://growsurf.com/',
    jobsUrl: 'https://www.linkedin.com/company/growsurf/jobs/',
  },
  {
    name: 'InboxDone',
    description:
      'Email management service that organizes and replies to your emails',
    founded: '2017',
    employeeCount: '11-50',
    location: 'Remote',
    homepageUrl: 'https://inboxdone.com/',
    jobsUrl: 'https://inboxdone.com/jobs-apply/',
  },
  {
    name: 'Join It',
    description:
      'SaaS platform that helps organizations sell and track their memberships',
    founded: '2016',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://www.joinit.org/',
    jobsUrl: 'https://www.linkedin.com/company/join-it/jobs/',
  },
  {
    name: 'lemlist',
    description: 'Sales automation and email software',
    founded: '2018',
    employeeCount: '11-50',
    location: 'Remote',
    homepageUrl: 'https://www.lemlist.com/',
    jobsUrl: 'https://www.welcometothejungle.com/fr/companies/lemlist/jobs',
  },
  {
    name: 'MVMT',
    description: 'Watch and apparel brand',
    founded: '2013 (acquired by Movado Group in 2018)',
    employeeCount: '11-50',
    location: 'Los Angeles, CA',
    homepageUrl: 'https://www.mvmt.com/',
    jobsUrl: 'https://www.linkedin.com/company/mvmt-watches-inc/jobs/',
  },
  {
    name: 'Parseur',
    description:
      'SaaS tool for automating data extraction from emails and documents',
    founded: '2016',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://parseur.com/',
    jobsUrl: 'https://www.linkedin.com/company/parseur.com/jobs/',
  },
  {
    name: 'Plutio',
    description: 'Business management app',
    founded: '2017',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://www.plutio.com/',
    jobsUrl: 'https://www.plutio.com/work-with-us',
  },
  {
    name: 'Referral Hero',
    description: 'Referral marketing platform',
    founded: '2016',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://referralhero.com/',
    jobsUrl: 'https://www.linkedin.com/company/referralhero/jobs/',
  },
  {
    name: 'Refiner',
    description: 'User feedback and customer survey tool for SaaS teams',
    founded: '2019',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://refiner.io/',
    jobsUrl: 'https://www.linkedin.com/company/refiner-io/jobs/',
  },
  {
    name: 'SiteGuru',
    description: 'SEO website auditing tool',
    founded: '2017',
    employeeCount: '0-10',
    location: 'Remote',
    homepageUrl: 'https://www.siteguru.co/',
    jobsUrl: 'https://www.linkedin.com/company/siteguruco/jobs/',
  },
  {
    name: 'SmartTask',
    description: 'Project management tool',
    founded: '2016',
    employeeCount: '11-50',
    location: 'India',
    homepageUrl: 'https://www.smarttask.io/',
    jobsUrl: 'https://www.linkedin.com/company/smart-tasks/jobs/',
  },
  {
    name: 'Tuft & Needle ',
    description: 'Direct-to-consumer foam mattresses',
    founded: '2012 (acquired by Serta Simmons in 2018)',
    employeeCount: '51-200',
    location: 'Phoenix, AZ',
    homepageUrl: 'https://www.tuftandneedle.com/',
    jobsUrl: 'https://careers.tn.com/positions/',
  },
  {
    name: 'VEED.IO',
    description: 'Online video editing',
    founded: '2018',
    employeeCount: '51-200',
    location: 'Remote',
    homepageUrl: 'https://www.veed.io/',
    jobsUrl: 'https://apply.workable.com/veed-io/?lng=en',
  },
];

const tranche2 = [
  {
    name: 'Mobicip',
    description: 'Internet filter and parental control app',
    founded: '2008',
    employeeCount: '51-200',
    location: 'California, USA',
    homepageUrl: 'https://www.mobicip.com',
    jobsUrl: 'https://www.mobicip.com/about',
  },
  {
    name: 'IKONA Partners',
    description: 'Independent technology investment bank',
    founded: '2022',
    employeeCount: '1-10',
    location: 'California, USA',
    homepageUrl: 'https://www.ikonapartners.com/',
    jobsUrl: 'https://www.ikonapartners.com/',
  },
  {
    name: '2ndVault',
    description: 'Digital vault for important information',
    founded: '2020',
    employeeCount: '1-10',
    location: 'USA',
    homepageUrl: 'https://2ndvault.com/',
    jobsUrl: 'https://2ndvault.com/about-us/',
  },
  {
    name: 'IMERSHEN, LLC',
    description: 'Interactive corporate training',
    founded: '2020',
    employeeCount: '1-10',
    location: 'Arizona, USA',
    homepageUrl: 'https://imershen.co',
    jobsUrl: 'https://imershen.co',
  },
  {
    name: 'Frameology',
    description: 'Print-on-demand company',
    founded: '2015',
    employeeCount: '1-10',
    location: 'USA',
    homepageUrl: 'https://frameology.com/',
    jobsUrl: 'https://angel.co/company/frameology/jobs ',
  },
  {
    name: 'Z-Terra',
    description: 'Seismic imaging software and services',
    founded: '2010',
    employeeCount: '51-200',
    location: 'USA',
    homepageUrl: 'https://www.z-terra.com/',
    jobsUrl: 'https://www.z-terra.com/9I7gsY2kIW8m/careers/',
  },
  {
    name: 'IdeaScale',
    description: 'Innovation management solution',
    founded: '2010',
    employeeCount: '11-51',
    location: 'USA',
    homepageUrl: 'http://ideascale.com',
    jobsUrl: 'https://ideascale.com/ideascale-careers/',
  },
  {
    name: 'They Got Acquired',
    description: 'Media company reporting on small-scale acquisitions',
    founded: '2021',
    employeeCount: '1-10',
    location: 'USA',
    homepageUrl: 'https://theygotacquired.com/ ',
    jobsUrl: 'https://theygotacquired.com/careers',
  },
  {
    name: 'Product10x',
    description: 'Startup accelerator',
    founded: '2019',
    employeeCount: '1-10',
    location: 'California, USA',
    homepageUrl: 'https://product10x.com/',
    jobsUrl: 'https://product10x.com/',
  },
  {
    name: 'QuantFish',
    description: 'Statistics education and research',
    founded: '2021',
    employeeCount: '1-10',
    location: 'West Virginia, USA',
    homepageUrl: 'https://www.goquantfish.com/',
    jobsUrl: 'https://www.goquantfish.com/teach',
  },
  {
    name: 'Userflow, Inc.',
    description: 'User onboarding platform',
    founded: '2018',
    employeeCount: '1-10',
    location: 'California, USA',
    homepageUrl: 'https://userflow.com',
    jobsUrl: 'https://userflow.com',
  },
  {
    name: 'Rolling Insights',
    description: 'Sports data insights provider',
    founded: '2011',
    employeeCount: '1-10',
    location: 'Canada',
    homepageUrl: 'https://rolling-insights.com/',
    jobsUrl: 'https://rollingwave.hrpartner.io/jobs',
  },
  {
    name: 'Onederful Finance',
    description: 'Patient financing options website',
    founded: '2020',
    employeeCount: '1-10',
    location: 'New York, USA',
    homepageUrl: 'https://onederfulfinance.com',
    jobsUrl: 'https://onederfulfinance.com/jobs/csm',
  },
  {
    name: 'Halfbrick',
    description: 'Mobile video game developer',
    founded: '2001',
    employeeCount: '51-200',
    location: 'Australia',
    homepageUrl: 'https://www.halfbrick.com',
    jobsUrl: 'https://www.halfbrick.com/careers',
  },
  {
    name: 'WebHR',
    description: 'All-in-one social HR software',
    founded: '2011',
    employeeCount: '51-200',
    location: 'USA',
    homepageUrl: 'https://web.hr',
    jobsUrl: 'https://web.hr/careers',
  },
  {
    name: 'incluude',
    description: 'Workplace and community diversity platform',
    founded: '2021',
    employeeCount: '1-10',
    location: 'Australia',
    homepageUrl: 'https://incluude.com',
    jobsUrl: 'https://incluude.com/jobs',
  },
  {
    name: 'The Marygold Companies',
    description: 'Global holding company',
    founded: '2015',
    employeeCount: '51-200',
    location: 'Nevada, USA',
    homepageUrl: 'http://www.themarygoldcompanies.com ',
    jobsUrl: 'http://www.themarygoldcompanies.com',
  },
];

const tranche3 = [
  {
    name: 'Retain Global',
    description: 'Human resources research',
    founded: '2007',
    employeeCount: '1-10',
    location: 'Nevada, USA',
    homepageUrl: 'https://www.retainglobal.com/',
    jobsUrl: 'https://www.retainglobal.com/contact-us/',
  },
  {
    name: 'DevITJobs',
    description: 'Job board focused on transparency in tech',
    founded: '2019',
    employeeCount: '1-10',
    location: 'USA',
    homepageUrl: 'https://devitjobs.us',
    jobsUrl: 'https://devitjobs.us',
  },
  {
    name: 'L1NK',
    description: 'Scheduling and invoicing app for sole proprietors',
    founded: '2021',
    employeeCount: '1-10',
    location: 'Australia',
    homepageUrl: 'https://l1nk.com.au/',
    jobsUrl: 'https://l1nk.com.au/',
  },
];

const tranche4 = [
  {
    name: 'unithrifts',
    description: 'Sustainable resale platform for collegiate apparel',
    founded: '2020',
    employeeCount: '1-10',
    location: 'California, USA',
    homepageUrl: 'https://www.unithrifts.com/',
    jobsUrl: 'https://www.unithrifts.com/',
  },
];

const tranche5 = [
  {
    name: 'Techimax',
    description: 'IT services and product engineering',
    founded: '2014',
    employeeCount: '201-500',
    location: 'India',
    homepageUrl: 'https://techimax.in',
    jobsUrl: 'https://techimax.in/careers/',
  },
  {
    name: 'route1.io',
    description: 'Advanced marketing and business analytics',
    founded: '2019',
    employeeCount: '1-10',
    location: 'Connecticut, USA',
    homepageUrl: 'http://www.route1.io',
    jobsUrl: 'https://www.route1.io/careers/',
  },
];

const tranche6 = [
  {
    name: 'Tradly',
    description: 'SaaS for launching commerce and multi-sided platforms.',
    founded: '2019',
    employeeCount: '1-10',
    location: 'Malaysia',
    homepageUrl: 'https://tradly.app/',
    jobsUrl: 'https://angel.co/company/tradly-platform/jobs',
  },
  {
    name: 'Carrot',
    description: 'Real estate site builder and lead management tools.',
    founded: '2014',
    employeeCount: '51-200',
    location: 'Oregon, USA',
    homepageUrl: 'https://carrot.com/',
    jobsUrl: 'https://careers.carrot.com/',
  },
];

const sortByName = items => {
  return items.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

const tranche7 = [
  {
    name: 'AgilityHealth',
    description: 'Business agility platform',
    founded: '2014',
    employeeCount: '51-200',
    location: 'Nebraska, USA',
    homepageUrl: 'https://agilityhealthradar.com/',
    jobsUrl: 'https://agilityhealthradar.com/about/careers',
  },
];

const tranche8and9 = [
  {
    name: 'SCMYUGA',
    description: 'IT services and consulting',
    founded: '2019',
    employeeCount: '11-51',
    location: 'India',
    homepageUrl: 'https://scmyuga.com/ ',
    jobsUrl: 'https://www.linkedin.com/company/scmyuga/jobs/',
  },
  {
    name: 'TeamKinetic',
    description: 'Volunteer management software',
    founded: '2011',
    employeeCount: '1-10',
    location: 'United Kingdom',
    homepageUrl: 'https://teamkinetic.co.uk/ ',
    jobsUrl: 'https://teamkinetic.co.uk/',
  },
  {
    name: 'Carla Car Rental',
    description: 'Mobile-first car rental platform',
    founded: '2017',
    employeeCount: '11-51',
    location: 'Delaware, USA',
    homepageUrl: 'https://rentcarla.com/',
    jobsUrl: 'https://www.linkedin.com/company/carla-car-rental/jobs/',
  },
  {
    name: 'Dixit Systems',
    description: 'Engineering and 3D software',
    founded: '2016',
    employeeCount: '1-10',
    location: 'New York, USA',
    homepageUrl: 'https://www.dixitsystems.com/',
    jobsUrl: 'https://www.dixitsystems.com/',
  },
];

const tranche10 = [
  {
    name: 'Brooklines Group Limited',
    description: 'Cleaning products supplier',
    founded: '2005',
    employeeCount: '1-10',
    location: 'Hong Kong',
    homepageUrl: 'https://www.brooklines.com/ ',
    jobsUrl: 'https://www.brooklines.com/',
  },
  {
    name: 'GlobalVision',
    description: 'Automated proofreading for the life sciences industry',
    founded: '1990',
    employeeCount: '51-200',
    location: 'Remote/Global',
    homepageUrl: 'https://globalvision.co/',
    jobsUrl: 'https://apply.workable.com/globalvision-careers/',
  },
];

const tranche11 = [
  {
    name: 'TeamForm',
    description: 'Human resources management software',
    founded: '2020',
    employeeCount: '11-50',
    location: 'Australia',
    homepageUrl: 'https://TeamForm.co',
    jobsUrl: 'https://TeamForm.co/careers',
  },
  {
    name: 'Optimize U, LLC',
    description: 'Organizational development services',
    founded: '2018',
    employeeCount: '1-10',
    location: 'Florida, USA',
    homepageUrl: 'https://www.optimizeuguru.com/',
    jobsUrl: 'https://www.optimizeuguru.com/',
  },
];

const tranche12 = [
  {
    name: 'Key Medium',
    description: 'Digital marketing agency',
    founded: '2014',
    employeeCount: '1-10',
    location: 'Delaware, USA',
    homepageUrl: 'https://keymedium.com',
    jobsUrl: 'https://keymedium.com/category/careers/',
  },
];

const tranche13 = [
  {
    name: 'Breeze',
    description: 'Carbon emission calculation and reduction services.',
    founded: '2022',
    employeeCount: '1-10',
    location: 'Canada',
    homepageUrl: 'https://mybreeze.io/',
    jobsUrl: 'https://www.circleenv.com/career',
  },
];

const tranche14 = [
  {
    name: 'EdisonOS',
    description: 'SaaS platform for building online learning programs',
    founded: '2021',
    employeeCount: '11-51',
    location: 'India',
    homepageUrl: 'https://www.edisonos.com',
    jobsUrl: 'https://www.edisonos.com/contact-us',
  },
];

const tranche15 = [
  {
    name: 'Invoice123',
    description: 'Invoicing and accounting platform',
    founded: '2022',
    employeeCount: '1-10',
    location: 'Lithuania',
    homepageUrl: 'https://invoice123.com/',
    jobsUrl: 'https://invoice123.com/about-us/',
  },
  {
    name: 'Middletwin',
    description: 'Membership management for car clubs',
    founded: '2020',
    employeeCount: '1-10',
    location: 'USA',
    homepageUrl: 'https://www.middletwin.com/',
    jobsUrl: 'https://www.middletwin.com/',
  },
  {
    name: 'PayorCRM',
    description: 'Finance CRM',
    founded: '2021',
    employeeCount: '1-10',
    location: 'India',
    homepageUrl: 'https://www.payorcrm.com/',
    jobsUrl: 'https://www.payorcrm.com/',
  },
  {
    name: 'Uncat',
    description: 'Transaction categorization software',
    founded: '2019',
    employeeCount: '1-10',
    location: 'Tennessee, USA',
    homepageUrl: 'https://uncat.com',
    jobsUrl: 'https://www.uncat.com/about',
  },
];

const tranche16 = [
  {
    name: 'Bookmark Llama',
    description: 'Bookmark-sharing browser extension',
    founded: '2022',
    employeeCount: '1-10',
    location: 'United Kingdom',
    homepageUrl: 'https://www.bookmarkllama.com/',
    jobsUrl: 'https://www.bookmarkllama.com/',
  },
  {
    name: 'Dynamis Center, LLC',
    description: 'Marketing agency',
    founded: '2017',
    employeeCount: '1-10',
    location: 'Illinois, USA',
    homepageUrl: 'https://dynamiscenter.com/',
    jobsUrl: 'https://www.linkedin.com/company/dynamiscenter/about/',
  },
  {
    name: 'Cove.eco',
    description: 'Online community for small business',
    founded: '2023',
    employeeCount: '1-10',
    location: 'California, USA',
    homepageUrl: 'https://www.cove.eco/',
    jobsUrl: 'https://www.linkedin.com/company/cove-eco',
  },
];

const mostRecentTranche = tranche16;

const allCompanies = sortByName([
  ...tranche1,
  ...tranche2,
  ...tranche3,
  ...tranche4,
  ...tranche5,
  ...tranche6,
  ...tranche7,
  ...tranche8and9,
  ...tranche10,
  ...tranche11,
  ...tranche12,
  ...tranche13,
  ...tranche14,
  ...tranche15,
  ...tranche16,
]);

export {
  tranche1,
  tranche2,
  tranche3,
  tranche4,
  tranche5,
  tranche6,
  tranche7,
  tranche8and9,
  tranche10,
  tranche11,
  tranche12,
  tranche13,
  tranche14,
  tranche15,
  tranche16,
  mostRecentTranche,
};

export default allCompanies;

import React, { useCallback, useState } from 'react';
import { SignupContext } from '../helpers/context';

const STEPS = {
  choose: 'choose',
  individual: 'individual',
  company: 'company',
  confirmation: 'confirmation',
};

const SignupProvider = ({ children }) => {
  const [step, setStep] = useState(STEPS.choose);
  const [submittedStep, setSubmittedStep] = useState();

  const showChooseStep = useCallback(() => {
    setStep(STEPS.choose);
  });

  const showIndividualForm = useCallback(() => {
    setStep(STEPS.individual);
  });

  const showCompanyForm = useCallback(() => {
    setStep(STEPS.company);
  });

  const showConfirmation = useCallback(() => {
    setSubmittedStep(step);
    setStep(STEPS.confirmation);
  });

  return (
    <SignupContext.Provider
      value={{
        step,
        submittedStep,
        showChooseStep,
        showIndividualForm,
        showCompanyForm,
        showConfirmation,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
};

export default SignupProvider;

import React from 'react';
import cx from 'classnames';
import './CompaniesList.css';
import BootstrapCompaniesHero from './CompaniesHero';
import sanitizeUrl from '../helpers/sanitizeUrl';
import companies, { mostRecentTranche } from '../resources/CompanyPledges';

const recentlyAddedNames = mostRecentTranche.map(company => company.name);

const tableHeader = [
  'Organization',
  'Description',
  'Founded',
  'No. of employees',
  'Location',
  'Open roles',
];

const header = 'Certified bootstrapped';
const subheader =
  'We achieve more when we bootstrap together. These companies are self-reliant and successful — ' +
  'with little to no outside funding.';

const CompanyRow = ({
  name,
  description,
  founded,
  employeeCount,
  location,
  homepageUrl,
  jobsUrl,
  recentlyAdded,
}) => {
  return (
    <div className='BootstrapCompaniesList--table-row'>
      <div className='BootstrapCompaniesList--table-cell px-1 md:px-2'>
        {recentlyAdded && <div className='eyebrow text-sm'>NEW</div>}
        <span className='mr-2'>{name}</span>
        <a
          href={homepageUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='no-animated-line hover:no-underline text-gray-500'
        >
          <i className='fa-regular fa-arrow-up-right-from-square' />
        </a>
      </div>
      <div className='BootstrapCompaniesList--table-cell px-1 md:px-2'>
        {description}
      </div>
      <div className='BootstrapCompaniesList--table-cell'>{founded}</div>
      <div className='BootstrapCompaniesList--table-cell'>{employeeCount}</div>
      <div className='BootstrapCompaniesList--table-cell'>{location}</div>
      <div className='BootstrapCompaniesList--table-cell'>
        <a
          href={jobsUrl}
          className='no-animated-line hover:no-underline'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='mr-2'>View open roles</span>
          <i className='fa-regular fa-arrow-up-right-from-square' />
        </a>
      </div>
    </div>
  );
};

export default () => {
  return (
    <div className='BootstrapCompaniesList'>
      <BootstrapCompaniesHero header={header} subheader={subheader} />
      <div className='BootstrapCompaniesList--table-wrapper'>
        <div className='BootstrapCompaniesList--table'>
          <div className='BootstrapCompaniesList--table-header BootstrapCompaniesList--table-row'>
            {tableHeader.map(colName => (
              <div
                key={colName}
                className={cx('BootstrapCompaniesList--table-cell', {
                  'px-1 md:px-2': ['Organization', 'Description'].includes(
                    colName
                  ),
                })}
              >
                {colName}
              </div>
            ))}
          </div>

          {companies.map(
            ({
              name,
              description,
              founded,
              employeeCount,
              location,
              homepageUrl,
              jobsUrl,
            }) => (
              <CompanyRow
                key={name}
                name={name}
                description={description}
                founded={founded}
                employeeCount={employeeCount}
                location={location}
                homepageUrl={sanitizeUrl(homepageUrl)}
                jobsUrl={sanitizeUrl(jobsUrl)}
                recentlyAdded={recentlyAddedNames.includes(name)}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

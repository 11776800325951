import React from 'react';
import Layout from '../components/Layout';
import CompaniesList from '../components/CompaniesList';
import Meta from '../components/Meta';

const Companies = () => {
  return (
    <Layout>
      <Meta
        title={'Certified Bootstrapped Companies | Sponsored by Aha!'}
        description={`Browse a list of bootstrapped companies that are proudly self-funded and self-reliant.`}
        keywords={'Bootstrap'}
        path={'/companies'}
      />
      <CompaniesList />
    </Layout>
  );
};

export default Companies;

import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import BrianSignature from '../images/BrianSignature.svg';
import ChrisSignature from '../images/ChrisSignature.svg';

export default function () {
  // PurgeCSS: pt-6 md:pt-12 grid grid-flow-row md:grid-flow-col justify-content-start
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showIndividualForm } = useContext(SignupContext);

  return (
    <>
      <div className='Content px-4'>
        <div className='heading-3 mt-20 mb-8'>
          Bootstrapping is for
          <span className='text-bootstrap-gradient ml-2' id='learnmore'>
            everyone
          </span>
        </div>
        <div className='bg-gray-50 rounded-md p-8 mb-8 subheading-one'>
          Bootstrapping is a mindset — for company founders, would-be
          entrepreneurs, and anyone else who wants to work more effectively in
          an organization. It is centered in creating real value with less
          waste. People think that hefty funding for their business or project
          will increase the chance of success. But we have seen the opposite is
          true.
        </div>
      </div>
      <div className='Section bg-white Content Content--bootstrap-landing-page-content'>
        <div className='container relative'>
          <h4 id='why-be-bootstrapped'>
            <span>Why be bootstrapped?</span>
          </h4>
          <p>
            <span>
              The moment a venture capital firm invests in your business, you
              are working for a banker. And not just any banker. This one is
              making bets on multiple companies simultaneously, aiming for a big
              return within six years or less. They are paid to pursue booms
              knowing most will be busts.{' '}
            </span>
          </p>
          <p>
            <span>
              The challenge for you is that you have only one investment — your
              company. The cost of being a bust is huge. Infusing a startup that
              does not require major research or infrastructure with wads of
              cash will not reveal answers or insights. It can hide problems
              though. (Or incentivize solving the wrong problems.){' '}
            </span>
          </p>
          <p>
            <span>
              You start thinking that you need to lose money to make money. Your
              focus is always on the next round of funding and your company
              valuation. You imagine being a unicorn. But for most businesses,
              money raised and valuation reached is not what really matters. We
              think founders should focus on tangible value created instead.
            </span>
          </p>
          <p>
            <a href='/companies' className='text-green-500'>
              Discover 100% bootstrapped companies{' '}
              <icon className='fa-regular fa-arrow-right' />
            </a>
          </p>
          <h4 id='bootstrappers-in-action'>
            <span>Bootstrappers in action</span>
          </h4>
          <p>
            The idea that you need significant budget and resources to start
            something new carries over in most companies. People compete to grow
            teams and acquire budget. That is unfortunate because building a
            bigger empire is not correlated with greater insights or innovation.{' '}
          </p>
          <p>
            Competing for resources does drain energy though. Hoarding
            knowledge, lack of cooperation, and functional squabbles lead to
            apathy and managing perceptions vs managing meaningful work. We
            think teams should be lean, limit their consumption, and put people
            first. That is how breakthroughs are realized and team spirit
            flourishes.{' '}
          </p>
          <h4 id='lets-grow-together'>Let's grow together</h4>
          <p>
            <span>
              Bootstrappers set your own growth trajectory. There are no big
              investor directives or top-down constraints. You know what your
              goals are and work iteratively to reach them. Success happens
              organically and you have the latitude to evaluate, understand, and
              solve based on what you believe will bring the most value.{' '}
            </span>
          </p>
          <p>
            <span>
              We are not the first to promote this concept. However, our
              approach has been surprisingly unique within the software
              industry. Aha! was founded to be completely bootstrapped, remote,
              and profitable — achieving $100M in annual recurring revenue in a
              relatively short period of time. This is our story and it can be
              yours too.
            </span>
          </p>
          <p>
            <span>
              Our goal is to spotlight outstanding bootstrapped companies and
              inspire people to think differently —&nbsp;about how you build new
              companies, projects, and creative programs through self-reliance
              and determination. Our hope is that the{' '}
            </span>
            <a href='/principles' className='text-green-500'>
              principles and benefits
            </a>
            <span>
              {' '}
              of bootstrapping enable you to contribute more to your customers,
              colleagues, and communities.{' '}
            </span>
          </p>
          <p className='pt-4'>
            <a
              href='#'
              className='text-green-500'
              onClick={() => {
                showIndividualForm();
                openBootstrapSignup({});
              }}
            >
              Sign the bootstrapper's pledge{' '}
              <icon className='fa-regular fa-arrow-right' />
            </a>
          </p>
          <div className='pt-6 md:pt-12 grid grid-flow-row md:grid-flow-col justify-content-start'>
            <img src={BrianSignature}></img>
            <img className='md:pl-8' src={ChrisSignature}></img>
          </div>
          <p>
            <strong>
              <span>
                Brian de Haaff and Dr. Chris Waters
                <br />
                Co-founders of Aha! and the Bootstrap Movement
              </span>
            </strong>
          </p>
        </div>
      </div>
    </>
  );
}

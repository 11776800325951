import React, { useCallback, useReducer } from 'react';
import cx from 'classnames';
import renderIf from 'render-if';
import { FlashMessageContext } from '../helpers/context';

const initialState = {
  flashMessage: '',
  flashMessageType: '',
};

const reducer = (state, action) => {
  if (action.type === 'SET_FLASH') {
    return {
      flashMessage: action.flashMessage,
      flashMessageType: action.flashMessageType,
    };
  } else if (action.type === 'CLEAR_FLASH') {
    return initialState;
  } else {
    return state;
  }
};

const FlashProvider = ({ children }) => {
  const [{ flashMessage, flashMessageType }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const setFlashMessage = useCallback(
    (flashMessageType, flashMessage) => {
      dispatch({
        type: 'SET_FLASH',
        flashMessage,
        flashMessageType,
      });
    },
    [dispatch]
  );

  const clearFlashMessage = useCallback(() => {
    dispatch({
      type: 'CLEAR_FLASH',
    });
  }, [dispatch]);

  return (
    <FlashMessageContext.Provider
      value={{ setFlashMessage, clearFlashMessage }}
    >
      {children}

      {renderIf(flashMessage)(
        <div className='fixed top-12 z-2000 w-full flex'>
          <div
            className={cx(
              'inline-flex items-start mx-auto min-w-72 max-w-3/4 py-4 px-8 rounded shadow-md min-w-1/4 border z-2000',
              {
                'bg-green-50 border-green-400 text-green-900':
                  flashMessageType === 'success',
                'bg-red-50 border-red-400 text-red-900':
                  flashMessageType === 'error',
              }
            )}
          >
            <div>{flashMessage}</div>
            <div className='flex-shrink-0 ml-auto pl-5'>
              <button
                type='button'
                className='group p-0 leading-none text-sm hover:text-red-500 focus:text-red-500 focus:outline-none'
                onClick={clearFlashMessage}
              >
                <i className='fa fa-times' />
              </button>
            </div>
          </div>
        </div>
      )}
    </FlashMessageContext.Provider>
  );
};

export default FlashProvider;

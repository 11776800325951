import { window } from 'browser-monads'; // this lets us use `window` in an ssr context

const debugMode = () => {
  return (
    window &&
    window.location &&
    window.location.origin &&
    window.location.origin !== 'https://bootstrap.company'
  );
};

const logAnalyticsInfoNonProd = (...messages) => {
  if (debugMode()) {
    console.log(`log.analytics ${messages}`);
  }
};

export default logAnalyticsInfoNonProd;

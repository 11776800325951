import React from 'react';
import './PrinciplesOverview.css';

const list = [
  {
    icon: (
      <i className='fa-regular fa-people-carry-box fa-3x text-bootstrap-gradient' />
    ),
    heading: 'Anchor purpose',
    text: 'Focus on people, performance, and profit. Exceptional people give their best when you lead with authenticity and offer room for achievement. You are fully responsible for the decisions you make — as well as the outcomes.',
  },
  {
    icon: <i className='fa-regular fa-kite fa-3x text-bootstrap-gradient' />,
    heading: 'Maintain freedom',
    text: 'Relish your autonomy. You answer only to colleagues, customers, community, and yourself. Lead with independence and infuse that energy into your interactions. Enjoy greater happiness and engagement.',
  },
  {
    icon: (
      <i className='fa-regular fa-handshake fa-3x text-bootstrap-gradient' />
    ),
    heading: 'Cherish value',
    text: 'Reject vanity and hype. Continued success is built on value exchanged between a business and the people it serves. Know the worth of what you offer and respect the customers who choose to buy from you.',
  },
  {
    icon: <i className='fa-regular fa-recycle fa-3x text-bootstrap-gradient' />,
    heading: 'Avoid waste',
    text: 'Use resources wisely. Abstain from non-core spend — perk-filled offices, expensive conferences, and fancy dinners. Only hire when you cannot afford not to. Eliminate meetings where the goal is not clear.',
  },
  {
    icon: (
      <i className='fa-regular fa-head-side-heart fa-3x text-bootstrap-gradient' />
    ),
    heading: 'Honor truth',
    text: 'Have conviction. Stay true to yourself and ward off drama. Ask those around you for feedback and listen closely. Look at yourself and the company with relentless clarity. Be transparent whenever you can.',
  },
  {
    icon: (
      <i className='fa-regular fa-chart-line-up fa-3x text-bootstrap-gradient' />
    ),
    heading: 'Sustain growth',
    text: 'Look downstream. Endurance is a strength and organic growth allows you to build with intention. Stability is underrated — do not make long-term decisions based on short-term needs. Prioritize and invest in what is lasting.',
  },
];

const PrinciplesOverviewHighlightCard = ({ icon, heading, text }) => (
  <div className='PrinciplesOverviewHighlightCard'>
    <span className='PrinciplesOverviewHighlightCard__icon text-bootstrap-gradient'>
      {icon}
    </span>
    <span className='PrinciplesOverviewHighlightCard__heading subheading-one'>
      {heading}
    </span>
    <p className='PrinciplesOverviewHighlightCard__text'>{text}</p>
  </div>
);

export default () => {
  return (
    <div className='BootstrapPrinciplesOverview my-16'>
      <div className='BootstrapPrinciplesOverview__heading my-8 text-bootstrap-gradient text-center'>
        What we believe
      </div>
      <div className='BootstrapPrinciplesOverview__subheading my-4 text-center paragraph-one'>
        Bootstrappers are self-reliant, purpose-driven, and dedicated to
        creating real value. You take a long-range view sustained by the
        following beliefs:
      </div>

      <div className='BootstrapPrinciplesOverview__grid mt-8 mx-12'>
        {list.map(({ icon, heading, text }) => (
          <PrinciplesOverviewHighlightCard
            key={heading}
            icon={icon}
            heading={heading}
            text={text}
          />
        ))}
      </div>
    </div>
  );
};

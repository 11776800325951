import React from 'react';
import Layout from '../components/Layout';
import PrinciplesHero from '../components/PrinciplesHero';
import PrinciplesOverview from '../components/PrinciplesOverview';
import PrinciplesBenefits from '../components/PrinciplesBenefits';
import Meta from '../components/Meta';

const Principles = () => {
  return (
    <Layout>
      <Meta
        title={'Principles of The Bootstrap Movement | Sponsored by Aha!'}
        description={`Bootstrappers are purpose-driven and dedicated to creating real value. Learn the 6 principles of being bootstrapped. `}
        keywords={'Bootstrap'}
        path={'/principles'}
      />
      <PrinciplesHero />
      <PrinciplesOverview />
      <PrinciplesBenefits />
    </Layout>
  );
};

export default Principles;

import React, { useContext } from 'react';
import FormControl from './FormControl';
import SubmitButton from './SubmitButton';
import useForm from '../hooks/useForm';
import { TrackingContext, SignupContext } from '../helpers/context';
import SocialShare from './SocialShare';
import EmailPrivacyMessage from './EmailPrivacyMessage';
import CountryOptions from './isoCountryOptions';
import StateOptions from './isoStateOptions';
import validations from '../helpers/validations';

const IndividualForm = () => {
  const signup = useContext(SignupContext);
  const { ga4Track } = useContext(TrackingContext);

  const { submitForm, handleChange, submitting, errors, fields } = useForm({
    action: 'bootstrapIndividualSignup',
    validations: {
      email: validations.email('email', { required: true }),
      fullName: validations.presence('Full name'),
      country: validations.presence('Country'),
    },
    successFn: () => {
      ga4Track('Bootstrap - Individual form submit', {});
      signup.showConfirmation();
    },
  });

  const isUsaSelected = fields?.country === 'United States of America';

  return (
    <>
      <h5 className='heading-5'>
        "I pledge to honor the bootstrapping principles"
      </h5>

      <form acceptCharset='UTF-8' onSubmit={submitForm}>
        <fieldset disabled={submitting} className='pt-4'>
          <FormControl
            error={errors.email}
            id='email'
            label='Email address'
            onChange={handleChange}
          />

          <FormControl
            error={errors.fullName}
            id='fullName'
            label='Full name'
            onChange={handleChange}
          />

          <div className='flex flex-wrap'>
            <FormControl
              error={errors.country}
              id='country'
              label='Country'
              onChange={handleChange}
              className='w-full md:w-1/2 md:pr-6'
              input={
                <select>
                  <CountryOptions />
                </select>
              }
            />

            {isUsaSelected && (
              <FormControl
                error={errors.state}
                id='state'
                label='State (U.S. only)'
                onChange={handleChange}
                className='w-full md:w-1/2 md:pl-6'
                input={
                  <select>
                    <StateOptions />
                  </select>
                }
              />
            )}
          </div>

          <div>
            <SubmitButton
              className='btn btn-teal block mt-5 ml-auto px-8 py-1'
              submitting={submitting}
            >
              Sign the pledge
            </SubmitButton>
            <SocialShare />
            <EmailPrivacyMessage version='individual' />
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default IndividualForm;

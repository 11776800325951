export const onRouteUpdate = ({ location }) => {
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;

    if (window.ga) {
      window.ga(`set`, `page`, pagePath);
      window.ga(`send`, `pageview`);
    }

    if (window.gtag) {
      // GA4 page_view event
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: pagePath,
        page_title: document.title,
      });
    }
  };

  setTimeout(sendPageView, 32);
};

import React, { useState } from 'react';
import './RecentlySigned.css';
import { tranche2 } from '../resources/IndividualPledges';

// PurgeCSS: text-red-600 text-blue-500 text-green-600 text-teal-600

const SignatoryCard = ({ iconColor, name, location }) => {
  return (
    <div className='BootstrapSignatoryCard p-8'>
      <i className={`fa-light fa-circle-user fa-2x text-${iconColor}`} />
      <h4 className='BootstrapSignatoryCard__name heading-4'>{name}</h4>
      <p className='paragraph-one'>{location}</p>
    </div>
  );
};

const RecentlySigned = () => {
  const [lists, setLists] = useState(tranche2);
  const displayedList = lists[0];

  return (
    <div className='BootstrapRecentlySigned' id='recently-signed'>
      <h3 className='heading-3 text-center my-16'>Recently signed</h3>

      <div className='BootstrapRecentlySigned__SignatoryGrid'>
        {displayedList.map(({ iconColor, name, location }) => (
          <SignatoryCard
            key={name}
            iconColor={iconColor}
            name={name}
            location={location}
          />
        ))}
      </div>
      <span
        className='BootstrapRecentlySigned__SignatoryGridLeftFade flex items-center'
        onClick={() => {
          setLists([lists[2], lists[0], lists[1]]);
        }}
      >
        <i className='BootstrapRecentlySigned__scroller fa-light fa-2x fa-chevron-left'></i>
      </span>
      <span
        className='BootstrapRecentlySigned__SignatoryGridRightFade flex items-center'
        onClick={() => {
          setLists([lists[1], lists[2], lists[0]]);
        }}
      >
        <i className='BootstrapRecentlySigned__scroller fa-light fa-2x fa-chevron-right'></i>
      </span>
    </div>
  );
};

export default RecentlySigned;

import * as React from 'react';
import { useEffect } from 'react';
import Layout from '../components/Layout';
import LandingHero from '../components/LandingHero';
import LandingPageContent from '../components/LandingPageContent';
import ListSignup from '../components/ListSignup';
import RecentlySigned from '../components/RecentlySigned';
import Meta from '../components/Meta';

const IndexPage = () => {
  useEffect(() => {
    try {
      const hash = location.hash;
      if (hash.length > 0) {
        const targetEl = document.querySelector(hash);
        if (hash && targetEl) {
          targetEl.scrollIntoView();
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <Layout hasDarkHeroBackground>
      <Meta
        title={'The Bootstrap Movement | Sponsored by Aha!'}
        description={`The Bootstrap Movement is an operating philosophy for anyone who wants build a business more effectively and contribute more to customers, colleagues, and communities.`}
        keywords={'Bootstrap'}
        path={'/'}
      />
      <LandingHero />
      <LandingPageContent />
      <ListSignup />
      <RecentlySigned />
    </Layout>
  );
};

export default IndexPage;

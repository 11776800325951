import { isNil, isEmpty } from 'lodash';

const presence = subject => {
  return value => {
    if (isNil(value) || isEmpty(value) || value === '') {
      return `${subject} is required`;
    }
  };
};

const url = (subject, { required }) => {
  return value => {
    let message;

    if (required) {
      message = presence(subject)(value);
      if (message) return message;
    }

    try {
      new URL(value);
    } catch (e) {
      message = `Valid URL required`;
    }

    if (message) return message;
  };
};

const email = (subject, { required }) => {
  return value => {
    if (required) {
      const message = presence(subject)(value);
      if (message) return message;
    }

    const pattern = /^[^@\s]+@[^.\s]+\.[^\s]+$/;
    if (!pattern.test(String(value))) {
      return `Valid email required`;
    }
  };
};

const year = (subject, { required }) => {
  return value => {
    if (required) {
      const message = presence(subject)(value);
      if (message) return message;
    }

    const number = parseInt(value);
    if (isNaN(number)) return `Please enter a number`;

    if (number > 3000) return `Please enter a valid year`;
    if (number < 1500) return `Please enter a valid year`;
  };
};

export default {
  presence,
  email,
  url,
  year,
};

import React, { useContext, useEffect, useState } from 'react';
import { window } from 'browser-monads';
import qs from 'query-string';
import NoSSR from './NoSSR';
import { TrackingContext } from '../helpers/context';
import { getRandom } from '../helpers/trackingId';

const TrackPixel = () => {
  const [visible, setVisible] = useState(true);
  const { trackingConsent, trackingId } = useContext(TrackingContext);
  const [random, setRandom] = useState(getRandom());

  useEffect(() => {
    // Every time we get popstate event, change the random parameter
    // so we track the new page that the user is on.
    const updateRandom = () => {
      setRandom(getRandom());
    };
    window.addEventListener('popstate', updateRandom);
    return () => window.removeEventListener('popstate', updateRandom);
  }, []);

  if (trackingConsent && visible) {
    const params = {
      trackingId,
      uri: window.location.pathname + window.location.search,
      referrer: window.document.referrer,
      req: random,
    };

    Object.entries(qs.parse(window.location.search)).forEach(([key, value]) => {
      if (key.startsWith('utm_')) {
        params[key] = value;
      }
    });

    return (
      <img
        src={`/.netlify/functions/pageView?${qs.stringify(params)}`}
        width={0}
        height={0}
        onError={() => setVisible(false)}
      />
    );
  } else {
    return null;
  }
};

const NoSSRPixel = () => {
  return (
    <NoSSR>
      <TrackPixel />
    </NoSSR>
  );
};

export default React.memo(NoSSRPixel);

import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import LargeWhiteBootSvg from '../images/large-white-boot.svg';
import WaveformSvg from '../images/waveform.svg';
import LandingLaceRight from '../images/landing-lace-right.svg';
import LandingLaceLeft from '../images/landing-lace-left.svg';
import './LandingHero.css';

const BootstrapLandingHighlightCard = ({ icon, text, link, onClickFn }) => {
  return (
    <a
      className='text-white no-animated-line hover:no-underline'
      href={link || '#'}
      onClick={onClickFn}
    >
      <div className='BootstrapLandingHighlightCard py-8 pl-8 pr-4'>
        <span className='BootstrapLandingHighlightCard__icon'>{icon}</span>

        <span className='BootstrapLandingHighlightCard__text subheading-one'>
          {text}
        </span>

        <span className='BootstrapLandingHighlightCard__caret'>
          <i className='fa-light fa-angle-right fa-2x' />
        </span>
      </div>
    </a>
  );
};

const Laces = () => (
  <div className='hidden lg:block'>
    <img className='BootstrapLandingLeftLace' src={LandingLaceLeft}></img>
    <img className='BootstrapLandingRightLace' src={LandingLaceRight}></img>
  </div>
);

const noop = () => {};

const LandingHero = () => {
  const { openBootstrapSignup } = useContext(ModalContext);
  const { showIndividualForm, showCompanyForm } = useContext(SignupContext);

  const cards = [
    {
      icon: <i className='fa-regular fa-book-heart fa-3x' />,
      text: 'Learn about the movement',
      link: '#learnmore',
      onClickFn: noop,
    },
    {
      icon: <i className='fa-regular fa-badge-check fa-3x' />,
      text: 'See the operating principles',
      link: '/principles',
      onClickFn: noop,
    },
    {
      icon: <i className='fa-regular fa-signature fa-3x' />,
      text: "Sign the bootstrapper's pledge",
      link: '',
      onClickFn: e => {
        e.preventDefault();
        showIndividualForm();
        openBootstrapSignup();
      },
    },
    {
      icon: <i className='fa-regular fa-apartment fa-3x' />,
      text: 'Add your bootstrapped company',
      link: '',
      onClickFn: e => {
        e.preventDefault();
        showCompanyForm();
        openBootstrapSignup();
      },
    },
  ];

  return (
    <>
      <div className='Section Section--bootstrap-gradient BootstrapLandingHero z-10'>
        <div className='BootstrapHeaderBumper' />
        <img className='BootstrapLandingHero__waveform' src={WaveformSvg} />
        <div className='BootstrapLandingHero__content z-30'>
          <img className='ml-auto mr-auto z-30' src={LargeWhiteBootSvg} />
          <span className='BootstrapLandingHero__title text-center'>
            <h1 className='heading-3 md:heading-1'>The Bootstrap</h1>
            <h1 className='heading-3 md:heading-1'>Movement</h1>
          </span>
          <h2 className='subheading-one text-center my-8'>
            An operating philosophy for anyone who wants to build more
            effectively
          </h2>

          <div className='BootstrapLandingHero__highlight-wrapper max-w md:max-w-md lg:max-w-xl xl:max-w-xl my-16'>
            {cards.map(({ icon, text, link, onClickFn }) => (
              <BootstrapLandingHighlightCard
                key={text}
                icon={icon}
                text={text}
                link={link}
                onClickFn={onClickFn}
              />
            ))}
          </div>
        </div>
      </div>
      <Laces />
    </>
  );
};

export default LandingHero;

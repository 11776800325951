import React from 'react';
import cx from 'classnames';
import renderIf from 'render-if';
import './FormControl.css';

export default function FormControl({
  className,
  style,
  labelClassName,
  labelStyle,
  inputClassName,
  inputStyle,
  errorClassName,
  label,
  input,
  error,
  id,
  onChange,
  value,
  type = 'text',
  labelParentClassName = 'block',
  placeholder,
  stepForm,
  ...inputProps
}) {
  let inputElement;

  if (input) {
    if (typeof input === 'function') {
      inputElement = input({ id, onChange, value });
    } else {
      inputElement = React.cloneElement(input, {
        onChange,
        value,
        id,
        name: id,
      });
    }
  } else {
    if (type === 'textarea') {
      inputElement = (
        <textarea
          name={id}
          id={id}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          {...inputProps}
        />
      );
    } else {
      inputElement = (
        <input
          type={type}
          name={id}
          id={id}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          {...inputProps}
        />
      );
    }
  }

  return (
    <div
      className={cx('FormControl', className, { error: error })}
      style={style}
    >
      <label className={labelParentClassName} htmlFor={id}>
        <div
          className={cx('FormControl__label font-light', labelClassName)}
          style={labelStyle}
        >
          {typeof label === 'function' ? label.call() : label}
        </div>
        <div
          className={cx('FormControl__input', inputClassName)}
          style={inputStyle}
        >
          {inputElement}
        </div>
        <div className='w-full h-0' />
        {renderIf(error && !stepForm)(
          <div className={cx('text-sm mt-1', errorClassName)}>
            <i className='fa fa-warning' /> {error}
          </div>
        )}
      </label>
      {renderIf(error && stepForm)(
        <div className='origin-top-left rounded-md shadow-lg w-64 z-10'>
          <div className='arrow-up ml-4'>
            <div className='arrow-up--outer' />
            <div className='arrow-up--inner' />
          </div>
          <div className='rounded-md bg-white shadow-xs p-4'>
            <p className='text-sm'> {error} </p>
          </div>
        </div>
      )}
    </div>
  );
}

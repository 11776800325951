import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export default Component => {
  return props => {
    const { site } = useStaticQuery(graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `);

    return <Component {...props} siteMetadata={site.siteMetadata} />;
  };
};

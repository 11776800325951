// https://big.aha.io/pages/TOOLS-N-345 Formats strings of event names we send
// to GA4 consistently. Can't use spaces or `-` there, but we want to use those
// in our event names to optimize for Looker discovery.
function formatEventName(eventName) {
  // Don't modify 'click', 'page_view', etc
  if (!eventName.includes(' ')) return eventName;

  let parts = eventName.split(' - ');

  // Capitalize strings between delimiters, remove spaces
  parts = parts.map((part, index) => {
    // Split by spaces, capitalize every word, and remove spaces
    return part
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  });

  // Join the parts with the new delimiter and return
  return parts.join('_');
}

export { formatEventName };

export const renderScripts = () => {
  Object.values(scripts).forEach(script => {
    const scriptTag = document.createElement('script');
    if (script.src) {
      scriptTag.src = script.src;
    } else {
      scriptTag.innerHTML = script.inline;
    }
    document.body.appendChild(scriptTag);
  });
};

const scripts = {
  googleTag: {
    src: 'https://www.googletagmanager.com/gtag/js?id=G-5LLWLJL284',
  },
  googleTagScript: {
    inline: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-5LLWLJL284');`,
  },
  googleAnalytics: {
    inline: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-40594204-10', 'auto', {'allowLinker': true});
  ga('create', '305584456', 'auto', 'clientTracker');
  ga('require', 'linker');
  ga('linker:autoLink', ['aha.io']);
  ga('set', 'anonymizeIp', true);
  ga('send', 'pageview');`,
  },
};

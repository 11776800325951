import React from 'react';
import Link from './Link';

const SocialShare = props => {
  const shareMessage =
    "I just signed the Bootstrapper's Pledge, sponsored by Aha! Check it out: bootstrap.company";

  return (
    <div>
      <h6 className='heading-6 mb-3 text-center mt-12'>Share why you joined</h6>

      <div className='flex justify-center'>
        <div
          data-href='https://bootstrap.company'
          data-layout='button_count'
          data-size='small'
        >
          <Link
            to='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fbootstrap.company%2F&amp;src=sdkpreparse'
            className='fb-xfbml-parse-ignore mx-2'
          >
            <i class='fa-brands fa-facebook'></i>
          </Link>
        </div>

        <Link
          to='https://www.linkedin.com/sharing/share-offsite/?url=https://bootstrap.company'
          className='mx-2'
        >
          <i class='fa-brands fa-linkedin'></i>
        </Link>

        <Link
          className='twitter-share-button mx-2'
          to={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
            shareMessage
          )}`}
        >
          <i class='fa-brands fa-twitter'></i>
        </Link>
      </div>
    </div>
  );
};

export default SocialShare;

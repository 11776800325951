import React, { useContext } from 'react';
import { ModalContext, SignupContext } from '../helpers/context';
import './Footer.css';
import logo from '../images/logo-white.svg';

const Footer = () => {
  // PurgeCSS: text-sm md:text-auto flex flex-row items-center flex-col md:flex-row
  const { showChooseStep } = useContext(SignupContext);
  const { openBootstrapSignup } = useContext(ModalContext);

  return (
    <>
      <div className='BootstrapFooter__slant' />
      <div className='BootstrapFooter__background-gradient'>
        <footer className='BootstrapFooter print:hidden text-white text-center'>
          <h4 className='heading-4 mt-8'>The Bootstrap Movement is for you</h4>
          <button
            onClick={() => {
              showChooseStep();
              openBootstrapSignup();
            }}
            className='BootstrapFooter__button btn btn-outline btn-small h-12 ml-2 lg:ml-4 mb-2 px-8'
          >
            Join now
          </button>
          <div className='BootstrapFooter__copyright text-sm md:text-auto mt-20 flex flex-col md:flex-row'>
            <div className='ml-4'>
              <span>© 2022 Aha! Labs Inc.</span>
              <span> • </span>
              <span>All rights reserved</span>
            </div>

            <div className='flex flex-row items-center text-sm md:text-auto'>
              <span>Sponsored by</span>
              <a
                href='https://aha.io'
                className='no-animated-line hover:no-underline'
              >
                <img className='inline h-6 ml-1' src={logo} />
              </a>
            </div>
            <div className='mr-4 text-sm md:text-auto flex flex-row'>
              <a href='https://aha.io/legal/privacy_policy'>Privacy policy</a>{' '}
              <span>• </span>
              <a href='https://www.aha.io/legal/terms_of_service'>
                Terms of service
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;

import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ModalContext } from '../helpers/context';

import './Link.css';

// Enhance the gatsby in-built link handler to make the link work with internal or external links,
// work with external links in a secure fashion, as well as work with special links like `tel:` and `mailto:`.
export default function EnhancedLink(props) {
  const { to, ...rest } = props;
  const enhancedProps = Object.assign({ href: to }, rest);

  // Don't destructure here because if this is being rendered as a job
  // description string, we might not be in a context where we have a
  // ModalProvider.
  const modalActions = useContext(ModalContext);

  if (/^http/.test(to)) {
    // If link is an external link, open in a blank tab and set correct `rel` attributes to avoid
    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    return (
      <a {...enhancedProps} target='_blank' rel='noopener noreferrer'>
        {enhancedProps.children}
      </a>
    );
  }
  if (/^(tel|mailto):/.test(to)) {
    // If link is a telephone or maito link, just add a plain link.
    return <a {...enhancedProps}>{enhancedProps.children}</a>;
  }

  if (/^\/\/assets\.ctfassets\.net/.test(to)) {
    // If points to a direct asset (for downloading), add a "download" attribute.
    return (
      <a {...enhancedProps} download>
        {enhancedProps.children}
      </a>
    );
  }

  // Convert any link to 'support@aha.io' to mail:support@aha.io.
  if (
    /^support@aha.io$/.test(to) ||
    /^support@aha.io$/.test(enhancedProps.children)
  ) {
    return <a href='mailto:support@aha.io'>{enhancedProps.children}</a>;
  }

  // If user clicks on a `#demo` link, open the demo modal.
  if (modalActions && /\/?#?demo$/.test(to)) {
    return (
      <a {...enhancedProps} onClick={modalActions.openDemo}>
        {props.children}
      </a>
    );
  }

  // If user clicks on a `#partner-help` link, open the request partner help modal.
  if (modalActions && /\/?#?partner-help/.test(to)) {
    return (
      <a {...enhancedProps} onClick={modalActions.openRequestPartnerHelp}>
        {props.children}
      </a>
    );
  }

  // If user clicks on a `#partner-apply` link, open the apply to be a partner modal.
  if (modalActions && /\/?#?partner-apply$/.test(to)) {
    return (
      <a {...enhancedProps} onClick={modalActions.openApplyToPartner}>
        {props.children}
      </a>
    );
  }

  // If user clicks on a `#trial` link, open the trial modal.
  if (
    modalActions &&
    /#?(?:free-)?trial(?:_user)?(-(roadmaps|ideas|develop))?$/.test(to)
  ) {
    const flavor = to.replace('#trial-', '').toUpperCase();
    return (
      <a
        {...enhancedProps}
        onClick={e => modalActions.openSignup(e, flavor, 'Content Link')}
      >
        {props.children}
      </a>
    );
  }

  // If user clicks on a `#trial` link for create, open the EAP modal
  if (modalActions && /#trial-create/.test(to)) {
    return (
      <a {...enhancedProps} onClick={e => modalActions.openApplyToCreateEAP()}>
        {props.children}
      </a>
    );
  }

  // Handle in-page anchor links correctly.
  if (/^#/.test(to)) {
    return <a {...enhancedProps}>{props.children}</a>;
  }

  // Handle in-page query param navigation correctly.
  if (/\?/.test(to)) {
    return <a {...enhancedProps}>{props.children}</a>;
  }

  // Surface error message when link is not resolveable.
  if (!props.to) {
    // Temporarily disabled to reduce log spam.
    // console.error(`No "to" attribute supplied for link:`, props);

    return (
      <a href='#' {...props}>
        {props.children}
      </a>
    );
  }

  // Otherwise return a standard Gatsby `<Link>`.
  return (
    <Link {...props} to={to}>
      {props.children}
    </Link>
  );
}

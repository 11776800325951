const iconColors = ['red-600', 'blue-500', 'green-600', 'red-600', 'teal-600'];

const tranche1 = [
  [
    {
      iconColor: 'red-600',
      name: 'Molly H.',
      location: 'Oregon',
    },
    {
      iconColor: 'blue-500',
      name: 'Claire G.',
      location: 'North Carolina',
    },
    {
      iconColor: 'green-600',
      name: 'James H.',
      location: 'Michigan',
    },
    {
      iconColor: 'red-600',
      name: 'Ashley B.',
      location: 'California',
    },
    {
      iconColor: 'teal-600',
      name: 'Jon E.',
      location: 'Pennsylvania',
    },
  ],
  [
    {
      iconColor: 'red-600',
      name: 'Alejandro S.',
      location: 'Florida',
    },
    {
      iconColor: 'blue-500',
      name: 'Amy E.',
      location: 'Georgia',
    },
    {
      iconColor: 'green-600',
      name: 'Claire J.',
      location: 'California',
    },
    {
      iconColor: 'red-600',
      name: 'Suthamie P.',
      location: 'Maryland',
    },
    {
      iconColor: 'teal-600',
      name: 'Madeleine B.',
      location: 'South Africa',
    },
  ],
  [
    {
      iconColor: 'red-600',
      name: 'Taylor L.',
      location: 'Oregon',
    },
    {
      iconColor: 'blue-500',
      name: 'Rose S.',
      location: 'Washington',
    },
    {
      iconColor: 'green-600',
      name: 'Mike A.',
      location: 'Washington',
    },
    {
      iconColor: 'red-600',
      name: 'Raven H.',
      location: 'Utah',
    },
    {
      iconColor: 'teal-600',
      name: 'Nathaniel C.',
      location: 'Georgia',
    },
  ],
];

const buildGroup = (names, locations, iconColors) => {
  return names.map((name, index) => {
    const iconColor = iconColors[index];
    const location = locations[index];

    return {
      iconColor,
      name,
      location,
    };
  });
};

// Note for Chris: @u is macro here
const tranche2 = [

  // technically tranche20
  buildGroup(
    ['Janae K.', 'Chandni P.', 'Andreya F.', 'Gregorio M.', 'Renee M.'],
    ['Washington, USA', 'Canada', 'Washington, USA', 'Italy', 'Kansas, USA'],
    iconColors
  ),

  buildGroup(
    ['Bruce F.', 'Heather F.', 'Riya S.', 'Fernando O.', 'Juan M.'],
    ['Finland', 'Canada', 'California, USA', 'Florida, USA', 'Colombia'],
    iconColors
  ),

  // technically tranche19
  buildGroup(
    ['Raym G.', 'Ling X.', 'Thiago Y.', 'Brent T.', 'Penny H.'],
    ['Canada', 'California, USA', 'Brazil', 'California, USA', 'New York, USA'],
    iconColors
  ),

  buildGroup(
    ['Priya J.', 'Codie M.', 'Angelica E.', 'Wade G.', 'Tairia B.'],
    ['India', 'Missouri, USA', 'Sweden', 'Minnesota, USA', 'Michigan, USA'],
    iconColors
  ),

  // technically tranche18

  buildGroup(
    ['Tiffany C.', 'Michelle C.', 'Litzy G.', 'Halil K.', 'Themba M.'],
    ['California, USA', 'United Kingdom', 'USA', 'Turkey', 'Georgia, USA'],
    iconColors
  ),

  // technically tranche17
  buildGroup(
    ['Daniel C.', 'Katie M. ', 'Brian A.', 'Nabeel A.', 'Nikki H.'],
    ['Mexico', 'Canada', 'Germany', 'Canada', 'Arkansas, USA'],
    iconColors
  ),

  // technically tranche16
  buildGroup(
    ['Allana A.', 'Kristin B.', 'Jude S.', 'Cliff F.', 'Juan L.'],
    [
      'Canada',
      'Oregon, USA',
      'Florida, USA',
      'Pennsylvania, USA',
      'Florida, USA',
    ],
    iconColors
  ),

  // technically tranche15
  buildGroup(
    ['Abhishek B.', 'Ricardo D.', 'Rodgers M.', 'Shenelle G.', 'Vaibhav S.'],
    ['Canada', 'Chile', 'Kenya', 'Rhode Island, USA', 'Massachusetts, USA'],
    iconColors
  ),

  // technically tranche14
  buildGroup(
    ['Yanira G.', 'Preeti S.', 'Rita', 'Amber W.', 'Robin R.'],
    ['United Kingdom', 'Canada', 'South Africa', 'Texas, USA', 'Colorado, USA'],
    iconColors
  ),

  // technically tranche13
  buildGroup(
    ['Ninya W.', 'Dónal O.', 'Elizabeth H.', 'Peter M.', 'Girish J.'],
    ['Iowa, USA', 'Ireland', 'Arizona, USA', 'Canada', 'California, USA'],
    iconColors
  ),

  // technically tranche12
  buildGroup(
    ['Lauen O.', 'Kris B.', 'Jelil D.', 'Alice S.', 'Corrinr N.'],
    ['United Kingdom', 'Canada', 'Ghana', 'Florida, USA', 'Rhode Island, USA'],
    iconColors
  ),
  // technically tranche 11
  buildGroup(
    ['Arjan N.', 'Rajesh K.', 'Alan M.', 'Lanine B.', 'Emelyn T.'],
    ['New Zealand', 'Texas, USA', 'Canada', 'USA', 'Canada'],
    iconColors
  ),
  // technically tranche 10
  buildGroup(
    ['David F.', 'David C.', 'Harikrishnan', 'Markus H.', 'Jennifer J.'],
    ['California', 'United Kingdom', 'India', 'Germany', 'Remote/Global'],
    iconColors
  ),
  // technically tranche 9
  buildGroup(
    ['April K.', 'Galo H.', 'Russell T.', 'Trevor M.', 'Antonio P.'],
    ['Tennessee', 'Ecuador', 'California', 'Oregon', 'Tennessee'],
    iconColors
  ),
  // technically tranche 8
  buildGroup(
    ['Dasarath R.', 'Timothy J. ', 'Lionel', 'Ian A.', 'Deb L.'],
    ['India', 'Virginia', 'Spain', 'USA', 'California'],
    iconColors
  ),
  // technically tranche 7
  buildGroup(
    ['True C.', 'George N.', 'Monica R.', 'Felix M.', 'Anne S.'],
    ['USA', 'New Zealand', 'South Africa', 'Kenya', 'Florida, USA'],
    iconColors
  ),
  // technically tranche 6
  buildGroup(
    ['Melissa H.', 'Noah K.', 'Gayle D.', 'Antony B.', 'Carter H.'],
    ['Florida, USA', 'New York, USA', 'Canada', 'New Zealand', 'USA'],
    iconColors
  ),

  // Technically tranche 5
  buildGroup(
    ['Sewu-S. T.', 'Victor M.', 'Patricia G. ', 'Sara D.', 'Dale P.'],
    ['Ghana', 'California', 'California', 'Washington', 'United Kingdom'],
    iconColors
  ),

  // Technically tranche 4
  buildGroup(
    ['Russell W.', 'Steffen B', 'José G.', 'Alex B', 'Russell B'],
    ['Australia', 'Denmark', 'Ecuador', 'Connecticut, USA', 'USA'],
    iconColors
  ),
  // Technically tranche3 but was wanted to be added to 2
  buildGroup(
    ['Cariad E.', 'Susan P.', 'Michael T.', 'Erin W.', 'Bernard P.'],
    ['United Kingdom', 'Remote', 'Australia', 'Georgia', 'Nevada'],
    iconColors
  ),

  buildGroup(
    ['William T.', 'Leon L.', 'James D.', 'Brennon G.', 'Ginger L.'],
    ['New York', 'Massachusetts', 'Singapore', 'New York', 'West Virginia'],
    iconColors
  ),
  buildGroup(
    ['Jason K.', 'Remy T.', 'Dave K.', 'Rhodemann L.', 'Shainiel D.'],
    ['New York', 'California', 'California', 'Connecticut', 'Australia'],
    iconColors
  ),
  buildGroup(
    ['Simon C.', 'Esben F.', 'Barry P.', 'Suresh M.', 'Mark E.'],
    ['Canada', 'California', 'Oregon', 'California', 'Canada'],
    iconColors
  ),
  buildGroup(
    ['Maxim M.', 'Mike F.', 'Alejandro S.', 'Stefan B.', 'Sushil R.'],
    ['California', 'California', 'Florida', 'Switzerland', 'Canada'],
    iconColors
  ),
];

export { tranche1, tranche2 };

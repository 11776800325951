import React, { useCallback, useContext, useEffect, useState } from 'react';
import renderIf from 'render-if';
import Modal from '../components/Modal';
import SignupModal from '../components/SignupModal';
import cx from 'classnames';

import { ModalContext, SignupContext } from '../helpers/context';
import usePrevious from '../hooks/usePrevious';

let scrollTop;
let scrollBarWidth;

const MODAL_TYPES = {
  bootstrapSignup: 'bootstrapSignup',
  closed: undefined,
};

const ModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(MODAL_TYPES.closed);
  const [openModalProps, setOpenModalProps] = useState(undefined);
  const prevOpenModal = usePrevious(openModal);
  const [modalContentClass, setModalContentClass] = useState('');
  const { step } = useContext(SignupContext);

  // Ensure that scroll position does not change, even now that the window itself is not scrolled.
  useEffect(() => {
    const openClass = 'no-scroll';
    const gatsbyElem = document.getElementById('___gatsby');
    if (openModal && prevOpenModal === MODAL_TYPES.closed) {
      // Scroll the gatsby container to the same amount as the main window. This gives the appearance that no scroll position has occurred.
      scrollTop = window.scrollY;
      scrollBarWidth = window.innerWidth - document.body.offsetWidth;
      document.body.classList.add(openClass);
      window.scroll(0, 0);
      gatsbyElem.style.right = `${scrollBarWidth}px`;
      gatsbyElem.scrollTop = scrollTop;
    } else if (openModal === MODAL_TYPES.closed) {
      gatsbyElem.style.right = '';
      document.body.classList.remove(openClass);

      // Check type because 0 is falsy in JS :(
      if (typeof scrollTop === 'number') {
        // Ensure that scroll position within the window is restored.
        window.scroll(0, scrollTop);
        gatsbyElem.style.right = '';
        scrollTop = undefined;
      }
    }
  }, [openModal, prevOpenModal]);

  const openBootstrapSignup = useCallback(() => {
    setOpenModalProps();
    setOpenModal(MODAL_TYPES.bootstrapSignup);
  }, []);

  const closeModal = useCallback(e => {
    if (e) e.preventDefault();
    setOpenModal(MODAL_TYPES.closed);
    setModalContentClass('bg-white'); // reset modal background color
  }, []);

  const isModalOpen = renderIf(openModal);
  const isModalType = type => renderIf(openModal === type);
  const isSignup = isModalType(MODAL_TYPES.bootstrapSignup);

  return (
    <ModalContext.Provider
      value={{
        openBootstrapSignup,
        openModal,
        closeModal,
      }}
    >
      {children}

      {isModalOpen(
        <Modal
          modalContentClass={cx({
            'Modal--companyform': step === 'company',
          })}
        >
          {isSignup(<SignupModal {...openModalProps} />)}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

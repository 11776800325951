// Because some things just cannot be rendered server-side. <ReactJson> I'm looking at you ...

import React from 'react';
import { window } from 'browser-monads';

export function OnlySSR(props) {
  // Inspect a property that only a real window would have. If it exists, then we must be rendering in the browser.
  if (window.innerWidth > 0) return null;
  return <>{props.children}</>;
}

export default function NoSSR(props) {
  // Inspect a property that only a real window would have. If it exists, then we must be rendering in the browser.
  if (window.innerWidth > 0) return <>{props.children}</>;
  return null;
}

import React, { useContext } from 'react';
import usePortal from 'react-useportal';
import { ModalContext } from '../helpers/context';
import './Modal.css';
import cx from 'classnames';

export default function Modal(props) {
  const { closeModal } = useContext(ModalContext);
  const { Portal } = usePortal();

  return (
    <Portal>
      <div className='Modal'>
        <div className='Modal__overlay' onClick={closeModal} />
        <div className={cx('Modal__content', props.modalContentClass)}>
          {props.children}
        </div>
      </div>
    </Portal>
  );
}

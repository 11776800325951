import React from 'react';
import cx from 'classnames';
import renderIf from 'render-if';

export default function SubmitButton(props) {
  return (
    <button
      type='submit'
      className={cx('relative', props.className)}
      id={props.id}
      disabled={props.disabled}
    >
      <div style={{ visibility: props.submitting ? 'hidden' : 'visible' }}>
        {props.children}
      </div>
      {renderIf(props.submitting)(
        <div className='absolute flex items-center justify-center inset-0'>
          <i className='fa fa-circle-o-notch fa-spin' />
        </div>
      )}
    </button>
  );
}

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { TrackingContext } from '../helpers/context';
import { renderScripts } from '../helpers/scripts';

const Meta = ({
  title,
  description,
  keywords,
  robots,
  path,
  noindex,
  meta = [],
  twitterCard = 'summary',
  ldJsonObj = null,
}) => {
  const { trackingConsent } = useContext(TrackingContext);

  const metaDescription = description;

  /* This url should match the @import url in Layout.css */
  const fontUrl =
    'https://fonts.googleapis.com/css?family=Inter:100,200,300,400,600,700,800,900|Caveat:400|Fira+Code:300&display=swap';

  // Render script tags onto the page once the user consents.
  useEffect(() => {
    if (trackingConsent && process.env.GATSBY_PRODUCTION) {
      renderScripts();
    }
  }, [trackingConsent]);

  if (process.env.GATSBY_GOOGLE_VERIFICATION) {
    meta.push({
      name: 'google-site-verification',
      content: process.env.GATSBY_GOOGLE_VERIFICATION,
    });
  }

  const canonicalUrl = 'https://bootstrap.company';

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={title}
        meta={[
          metaDescription && {
            name: 'description',
            content: metaDescription,
          },
          keywords && {
            name: 'keywords',
            content: keywords,
          },
          path && {
            property: 'og:url',
            content: `${canonicalUrl}${path}`,
          },
          {
            property: 'og:title',
            content: title,
          },
          metaDescription && {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:image',
            content: '/social/bootstrap-og-image.png',
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            name: 'twitter:card',
            content: twitterCard,
          },
          {
            name: 'twitter:creator',
            content: '@aha_io',
          },
          {
            name: 'twitter:title',
            content: title,
          },
          metaDescription && {
            name: 'twitter:description',
            content: metaDescription,
          },
          noindex &&
            !robots && {
              name: 'robots',
              content: 'noindex',
            },
          robots && {
            name: 'robots',
            content: robots.join(','),
          },
          ...meta,
        ].filter(Boolean)}
      >
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='true'
        />
        <link rel='preload' as='style' href={fontUrl} />
        <link
          rel='stylesheet'
          media='print'
          onLoad="this.media='all'"
          href={fontUrl}
        />

        <link rel='icon' type='image/png' href='/favicon.png' />
        <link rel='canonical' href={`${canonicalUrl}${path}`} />

        {ldJsonObj && (
          <script type='application/ld+json'>
            {JSON.stringify(ldJsonObj)}
          </script>
        )}
      </Helmet>
    </>
  );
};

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Meta;
